import React, { PropsWithChildren } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import routes from '../../common/routes';
import { useAuth } from '../../contexts/useAuth';

const PrivateRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const { validating, validToken } = useAuth();
  const location = useLocation();

  return validating ? (
    <></>
  ) : (<>
    {validToken ?
      children
      : (
        <Navigate to={routes.login} state={{ from: location }} replace />
      )}
  </>
  );
};

export default PrivateRoute;
