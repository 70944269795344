import moment from 'moment';

import { Person } from './Person';
import { DISPLAY_DATE_FORMAT } from '../../common/constants';

interface Employee extends Person {
  role: string;
  user_id: number;
  start_date: string;
}

const employeeDefaultValues = {
  date_of_birth: moment().format(DISPLAY_DATE_FORMAT),
  id_card_type: '',
  start_date:  moment().format(DISPLAY_DATE_FORMAT),
  user_id: 0,
};



export type { Employee };

export {
  employeeDefaultValues,
};
