import React from 'react';

import { formatAsCodeNumber } from '../../../common/utils';

const CodeNumber: React.FC<{value: string | number}> = ({ value }) => {
  return (
    <>{formatAsCodeNumber(value)}</>
  );
};

export default CodeNumber;
