import React from 'react';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import LocalGasStation from '@material-ui/icons/LocalGasStation';
import Money from '@material-ui/icons/Money';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import PeopleIcon from '@material-ui/icons/People';
import PeopleIconAlt from '@material-ui/icons/PeopleOutline';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import { Link, useNavigate } from 'react-router-dom';

import routes from '../../common/routes';
import { useAuth } from '../../contexts/useAuth';

interface Props {
  classes: ClassNameMap;
}

const DrawerLinks: React.FC<Props> = ({ classes }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate(routes.login, { replace: true });
  };

  return (
    <>
      <Divider />
      <List>
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          className={classes.drawerListGroupTitle}
        >
          Cobranza
        </Typography>
        <Link to={routes.loans.dailyCustomerList} className={classes.drawerListButton}>
          <ListItem button>
            <ListItemIcon><HowToRegIcon /></ListItemIcon>
            <ListItemText primary="Clientes del dia" />
          </ListItem>
        </Link>
        <Link to={routes.payments.list} className={classes.drawerListButton}>
          <ListItem button>
            <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
            <ListItemText primary="Pagos" />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          className={classes.drawerListGroupTitle}
        >
          Créditos
        </Typography>
        <Link to={routes.loans.loans.list} className={classes.drawerListButton}>
          <ListItem button>
            <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
            <ListItemText primary="Contratos" />
          </ListItem>
        </Link>
        <Link to={routes.loans.quote} className={classes.drawerListButton}>
          <ListItem button>
            <ListItemIcon><SwapVerticalCircleIcon /></ListItemIcon>
            <ListItemText primary="Cotización" />
          </ListItem>
        </Link>
        <Link to={routes.customer.list} className={classes.drawerListButton}>
          <ListItem button>
            <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
            <ListItemText primary="Clientes" />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          className={classes.drawerListGroupTitle}
        >
          Reportes
        </Typography>
        <Link to={routes.reports.monthly_closings.list} className={classes.drawerListButton}>
          <ListItem button>
            <ListItemIcon><TrendingUpIcon /></ListItemIcon>
            <ListItemText primary="Cierre Mensual" />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          className={classes.drawerListGroupTitle}
        >
          Operaciones Diarias
        </Typography>
        <Link to={routes.dailyOperations.salesmenGasExpenses.list} className={classes.drawerListButton}>
          <ListItem button>
            <ListItemIcon><LocalGasStation /></ListItemIcon>
            <ListItemText primary="Gastos de Gasolina" />
          </ListItem>
        </Link>
        <Link to={routes.dailyOperations.miscExpenses.list} className={classes.drawerListButton}>
          <ListItem button>
            <ListItemIcon><Money /></ListItemIcon>
            <ListItemText primary="Gastos Varios" />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          className={classes.drawerListGroupTitle}
        >
          Admin
        </Typography>
        <Link to={routes.admin.users.list} className={classes.drawerListButton}>
          <ListItem button>
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary="Usuarios" />
          </ListItem>
        </Link>
        <Link to={routes.admin.employees.list} className={classes.drawerListButton}>
          <ListItem button>
            <ListItemIcon><PeopleIconAlt /></ListItemIcon>
            <ListItemText primary="Personal" />
          </ListItem>
        </Link>
        <Link to={routes.admin.salesmen.list} className={classes.drawerListButton}>
          <ListItem button>
            <ListItemIcon><MotorcycleIcon /></ListItemIcon>
            <ListItemText primary="Gestores" />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <Link to="#" className={classes.drawerListButton} onClick={handleLogout}>
        <ListItem button>
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary="Cerrar sesión" />
        </ListItem>
      </Link>
    </>
  );
};

export default DrawerLinks;
