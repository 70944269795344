import React from 'react';

import styled from 'styled-components';

import { TextAlignType } from '../../../common/types';

const Wrapper = styled.div<{ align: string }>`
  text-align: ${props => props.align};
`;

interface Props  {
  value: string | number | undefined;
  // eslint-disable-next-line react/require-default-props
  align: TextAlignType,
}
const AlignedColumn: React.FC<Props> = ({ value, align }) => (
  <Wrapper align={align}>{value}</Wrapper>
);


export default AlignedColumn;
