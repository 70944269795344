import React from 'react';

import {
  FormControl, FormHelperText, InputLabel, Select,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import {
  useFormContext, RegisterOptions, Controller, FieldError,
} from 'react-hook-form';
import styled from 'styled-components';

const InputWrapper = styled.div`
  padding: 0 0 1em 0;
`;

interface FieldOptions {
  label: string;
  value: string;
}

interface Props {
  name: string;
  label: string;
  // eslint-disable-next-line react/require-default-props
  placeholder?: string;
  // eslint-disable-next-line react/require-default-props
  validations?: RegisterOptions;
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
  options: FieldOptions[];
}

const FormSelectField: React.FC<Props> = ({
  name,
  label,
  validations,
  required = false,
  options,
}) => {
  const { control } = useFormContext();

  const fieldValidations = validations ?? {};
  if (required) {
    fieldValidations.required = required;
  }

  const getErrorMessage = (error: FieldError) => {
    let message = error?.message;
    if (message === '' && required) {
      message = 'Este campo es requerido';
    }
    return message;
  };

  return (
    <>
      <Controller
        render={({ field, formState: { errors } }) => (
          <InputWrapper>
            <FormControl error={Boolean(errors[name])} fullWidth>
              <InputLabel
                id={name}
                shrink={field.value || field.value === 0}
              >
                {label}
              </InputLabel>
              <Select { ...field }>
                {options.map(option => (
                  <MenuItem value={option.value} key={`${name}-option-${option.value}`}>{option.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{getErrorMessage(errors[name] as FieldError)}</FormHelperText>
            </FormControl>
          </InputWrapper>
        )}
        name={name}
        control={control}
        rules={fieldValidations}
      />
    </>
  );
};

export default FormSelectField;
