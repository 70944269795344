import React, { useState } from 'react';

import {
  FormControl, InputLabel, Popover,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CalendarToday from '@material-ui/icons/CalendarToday';
import DateRange from '@material-ui/icons/DateRange';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import moment from 'moment';
import styled from 'styled-components';

import DateInputField from './DateInputField';
import {
  DateFilterModeType, DateFilterType, DateFilterValueType,
} from '../../common/types';
import { formatDate } from '../../common/utils';

const PopoverContent = styled.div`
  padding: 1em;
  max-width: 600px;
`;

const CenterContent = styled.div`
  display: flex;
  justify-content: center;
`;

const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface Props {
  filterMode: DateFilterModeType;
  filterValue: DateFilterValueType;
  // eslint-disable-next-line no-unused-vars
  onChange: (filter: DateFilterType) => void;
}

const DateFilterField: React.FC<Props> = ({
  filterMode, filterValue, onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [internalDateFilter, setInternalDateFilter] = useState<DateFilterType>({
    filterMode,
    filterValue,
  });
  const open = Boolean(anchorEl);
  let buttonLabel = '';

  const safeFormat = (date: moment.Moment | null) => {
    if (date === null) {
      return '';
    }

    return formatDate(date, 'display_date');
  };

  if (filterValue === null || filterValue?.from === null || filterValue?.to === null) {
    buttonLabel = 'Filtrar por fecha';
  } else {
    switch (filterMode) {
      case 'single_date':
        buttonLabel = formatDate(filterValue as moment.Moment, 'display_date');
        break;
      case 'date_range':
        buttonLabel = `${safeFormat(filterValue?.from as moment.Moment | null)} - ${safeFormat(filterValue?.to as moment.Moment | null)}`;
        break;
      default:
        buttonLabel = 'Filtrar por fecha';
        break;
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSingleDateChange = (value: moment.Moment | null) => {
    setInternalDateFilter({
      filterMode: internalDateFilter.filterMode,
      filterValue: value,
    });
  };

  const handleDateFromChange = (value: moment.Moment | null) => {
    setInternalDateFilter({
      filterMode: internalDateFilter.filterMode,
      filterValue: {
        from: value ?? moment(),
        to: internalDateFilter.filterValue?.to as moment.Moment ?? value ?? moment(),
      },
    });
  };

  const handleDateToChange = (value: moment.Moment | null) => {
    setInternalDateFilter({
      filterMode: internalDateFilter.filterMode,
      filterValue: {
        from: internalDateFilter.filterValue?.from as moment.Moment ?? value ?? moment(),
        to: value ?? moment(),
      },
    });
  };

  const handleChangeFilterMode = (event: React.MouseEvent<HTMLElement>, value: string) => {
    const newFilterMode = value as DateFilterModeType;
    let newFilterValue: DateFilterValueType = null;

    switch (newFilterMode) {
      case 'single_date':
        newFilterValue = moment();
        break;
      case 'date_range':
        newFilterValue = {
          from: moment(),
          to: moment(),
        };
        break;
    }

    setInternalDateFilter({
      filterMode: newFilterMode,
      filterValue: newFilterValue,
    });
  };

  const handleApply = () => {
    onChange(internalDateFilter);
    handleClose();
  };

  const handleClean = () => {
    onChange({
      filterMode: 'none',
      filterValue: null,
    });
    handleClose();
  };

  return (
    <>
      <Button variant="text" onClick={handleClick}>{buttonLabel}</Button>
      {filterMode !== 'none' &&  <Button variant="text" onClick={handleClean}>Limpiar Filtro</Button> }
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <PopoverContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CenterContent>
                <ToggleButtonGroup
                  value={internalDateFilter.filterMode}
                  exclusive
                  onChange={handleChangeFilterMode}
                >
                  <ToggleButton value="single_date" aria-label="list">
                    Fecha Única&nbsp;<CalendarToday />
                  </ToggleButton>
                  <ToggleButton value="date_range" aria-label="quilt">
                    Rango de Fecha&nbsp;<DateRange />
                  </ToggleButton>
                </ToggleButtonGroup>
              </CenterContent>
            </Grid>
            {internalDateFilter.filterMode === 'single_date' && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    id="report-date"
                    shrink={Boolean(internalDateFilter.filterValue)}
                  >
                Fecha
                  </InputLabel>
                  <DateInputField
                    id="report-date"
                    value={internalDateFilter.filterValue as moment.Moment}
                    onChange={value => handleSingleDateChange(value)}
                    todayButton
                  />
                </FormControl>
              </Grid>
            )}
            {internalDateFilter.filterMode === 'date_range' && (
              <>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="date-from"
                      shrink={Boolean(internalDateFilter.filterValue)}
                    >
                      Desde
                    </InputLabel>
                    <DateInputField
                      id="date-from"
                      value={internalDateFilter!.filterValue!.from as moment.Moment}
                      onChange={value => handleDateFromChange(value)}
                      todayButton
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="date-to"
                      shrink={Boolean(internalDateFilter.filterValue)}
                    >
                      Hasta
                    </InputLabel>
                    <DateInputField
                      id="date-to"
                      value={internalDateFilter!.filterValue!.to as moment.Moment}
                      onChange={value => handleDateToChange(value)}
                      todayButton
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <RightContent>
                <Button color="primary" variant="outlined" onClick={handleClose}>Cancelar</Button>
                  &nbsp;
                <Button color="primary" variant="contained" onClick={handleApply}>Aplicar</Button>
              </RightContent>
            </Grid>
          </Grid>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default DateFilterField;

