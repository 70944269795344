import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import DashboardGroup from './DashboardGroup';
import { PaymentsSummary } from '../../api/models/Payment';
import { getPaymentSummary } from '../../api/payments';
import { formatNumber } from '../../common/utils';

const StyledTable = styled(Table)`
  width: 100%;
`;

const HeaderCell = styled(TableCell)`
  font-weight: bold;
`;

const NumberCell = styled.div`
  text-align: right;
`;

const NumberHeaderCell = styled(HeaderCell)`
  text-align: right;
`;

const TodayPaymentsSummary: React.FC = () => {
  const isLoading = useRef<boolean>(false);
  const isFirstLoad = useRef<boolean>(true);
  const [paymentsSummaries, setPaymentsSummaries] = useState<PaymentsSummary[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const fetch = useCallback(async () => {
    isLoading.current = true;
    const { data, error } = await getPaymentSummary(undefined, undefined, undefined, undefined, { type: 'today' });
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (data) {
      setPaymentsSummaries(data.records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
    isFirstLoad.current = false;
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (paymentsSummaries.length === 0 && !isLoading.current) {
      fetch();
    } else if (!isFirstLoad.current && !isLoading.current) {
      fetch();
    }
  }, [fetch, paymentsSummaries.length]);

  return (
    <DashboardGroup label="Resumen de pagos de hoy">
      <TableContainer component={Paper}>
        <StyledTable aria-label="payment summary table">
          <TableHead>
            <TableRow>
              <HeaderCell>Gestor</HeaderCell>
              <NumberHeaderCell>Pagos Recibidos</NumberHeaderCell>
              <NumberHeaderCell>Total Recibido</NumberHeaderCell>
              {/*<NumberHeaderCell />*/}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              paymentsSummaries.map(ps => {
                return (
                  <TableRow key={`sale-${ps.salesman_id}-row`}>
                    <TableCell>
                      {ps.salesman_name}
                    </TableCell>
                    <TableCell>
                      <NumberCell>{formatNumber('two-decimals', ps.payment_count)}</NumberCell>
                    </TableCell>
                    <TableCell>
                      <NumberCell>{formatNumber('money', ps.payment_total_amount)}</NumberCell>
                    </TableCell>
                    {/*<TableCell />*/}
                  </TableRow>
                );
              })
            }
          </TableBody>
        </StyledTable>
      </TableContainer>

    </DashboardGroup>
  );
};

export default TodayPaymentsSummary;
