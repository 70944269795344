const routes = {
  admin: {
    employees: {
      form: '/admin/employees/:id',
      list: '/admin/employees',
    },
    salesmen: {
      conciliate: '/admin/salesmen/:id/conciliate',
      form: '/admin/salesmen/:id',
      list: '/admin/salesmen',
    },
    users: {
      form: '/admin/users/:id',
      list: '/admin/users',
    },
  },
  customer: {
    form: '/customers/:id',
    list: '/customers',
    paymentForm: '/customers/:id/payment-form',
  },
  dailyOperations: {
    miscExpenses: {
      form: '/daily_operations/misc_expenses/:id',
      list: '/daily_operations/misc_expenses',
    },
    salesmenGasExpenses: {
      form: '/daily_operations/salesmen_gas_expenses/:id',
      list: '/daily_operations/salesmen_gas_expenses',
    },
  },
  dashboard: '/',
  loans: {
    dailyCustomerList: '/loans/daily-customer-list',
    loans: {
      form: '/loans/loans/:id',
      list: '/loans/loans',
    },
    quote: '/loans/quote',
  },
  login: '/login',
  payments: {
    form: '/payments/:id',
    list: '/payments',
  },
  reports: {
    monthly_closings: {
      form: '/reports/monthly_closings/:id',
      list: '/reports/monthly_closings',
    },
  },
};

export default routes;
