import React, {
  useCallback, useEffect, useRef,
} from 'react';

import { useSnackbar } from 'notistack';
import {
  useForm, SubmitHandler, FormProvider,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Customer } from '../../../api/models/Customer';
import {
  createCustomer, getCustomer, updateCustomer,
} from '../../../api/sales';
import routes from '../../../common/routes';
import { useTenant } from '../../../contexts/useTenant';
import FormContentLayout from '../../layout/FormContentLayout';
import MainLayout from '../../layout/MainLayout';
import ActionButton from '../../shared/ActionButton';
import BreadCrumbs from '../../shared/BreadCrumbs';
import FormTextField from '../../shared/FormTextField';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
`;

const bcHistory = [{
  label: 'Clientes',
  url: routes.customer.list,
}];

const CustomerForm: React.FC = () => {
  const { currentTenantId } = useTenant();
  const methods = useForm<Customer>();
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const recordId = params.id;
  const isEdit = Boolean(recordId && recordId !== 'new');
  const isLoading = useRef<boolean>(false);

  const fetch = useCallback(async () => {
    isLoading.current = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = await getCustomer(recordId!);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      methods.setValue('id', data.id);
      methods.setValue('first_name_1', data.first_name_1);
      methods.setValue('first_name_2', data.first_name_2);
      methods.setValue('lastname_1', data.lastname_1);
      methods.setValue('lastname_2', data.lastname_2);
      methods.setValue('cellphone_number', data.cellphone_number);
      methods.setValue('id_card_number', data.id_card_number);
      methods.setValue('home_address', data.home_address);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
  }, [methods, recordId, enqueueSnackbar]);

  useEffect(() => {
    if (isEdit && !isLoading.current) {
      fetch();
    }
  }, [isEdit, isLoading, fetch]);

  const onSubmit: SubmitHandler<Customer> = async record => {
    if (isEdit) {
      const { error } = await updateCustomer(record);
      if (error) {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        enqueueSnackbar('Registro actualizado.', { variant: 'success' });
        navigate(routes.customer.list);
      }
      return;
    }

    const { error } = await createCustomer(record);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      enqueueSnackbar('Registro creado', { variant: 'success' });
      navigate(routes.customer.list);
    }
  };

  return (
    <MainLayout>
      <BreadCrumbs currentPage={isEdit ? 'Editar' : 'Crear'} history={bcHistory} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormContentLayout label="Clientes">
            <input {...methods.register('tenant_id')} value={currentTenantId} type="hidden"/>
            <FormTextField
              name="name"
              label="Nombre"
              required
            />
            <FormTextField
              name="lastname"
              label="Apellido"
            />
            <FormTextField
              name="home_address"
              label="Dirección"
              required
            />
            <FormTextField
              name="phone_numbers"
              label="Números de teléfono"
              required
            />
            <FormTextField
              name="id_card_number"
              label="Número de cédula"
            />
            <FormTextField
              name="lead_last_loan_amount"
              label="Monto último préstamo"
            />
            <FormTextField
              name="business"
              label="Negocio"
            />
            <ActionsWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => navigate(-1)}
                className="action-button"
              >
              Cancelar
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                className="action-button"
              >
              Guardar
              </ActionButton>
            </ActionsWrapper>
          </FormContentLayout>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default CustomerForm;
