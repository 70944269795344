import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useNavigate,  useSearchParams } from 'react-router-dom';

import { getLoanList } from '../../../api/loan';
import { Loan } from '../../../api/models/Loan';
import {
  getCurrentPageFromParams, getPerPageFromParams, getUrlWithPagination,
} from '../../../api/routes';
import routes from '../../../common/routes';
import { formatDate } from '../../../common/utils';
import MainLayout from '../../layout/MainLayout';
import BreadCrumbs from '../../shared/BreadCrumbs';
import AlignedColumn from '../../shared/list_view_column_renderers/AlignedColumn';
import CodeNumber from '../../shared/list_view_column_renderers/CodeNumber';
import NumericColumn from '../../shared/list_view_column_renderers/NumericColumn';
import ListViewTable, { getTableRows, RenderersListType } from '../../shared/ListViewTable';
import Spinner from '../../shared/Spinner';

const LoanList: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [loans, setLoans] = useState<Loan[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(getCurrentPageFromParams(searchParams.get('page')));
  const [perPage, setPerPage] = useState(getPerPageFromParams(searchParams.get('per_page')));
  const isLoading = useRef<boolean>(false);
  const isFirstLoad = useRef<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const tableHeaders = ['No', 'Cliente', 'Fecha (Desembolso)',
    <AlignedColumn align="right" value="Monto Desembolsado" />,
    <AlignedColumn align="right" value="Tasa de Interés" />,
    <AlignedColumn align="right" value="Plazo (Meses)" />,
    <AlignedColumn align="right" value="Saldo" />,
  ];

  const fetch = useCallback(async () => {
    isLoading.current = true;
    const { data, error } = await getLoanList(currentPage, perPage);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (data) {
      setLoans(data.records);
      setTotalRecords(data.pagination.total_records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
    isFirstLoad.current = false;
  }, [currentPage, perPage, enqueueSnackbar]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetch();
      navigate(getUrlWithPagination(routes.loans.loans.list, currentPage, perPage), { replace: true });
    }
  }, [currentPage, perPage, isFirstLoad, fetch, navigate]);

  useEffect(() => {
    if (loans.length === 0 && !isLoading.current) {
      fetch();
    }
  }, [fetch, loans.length, isLoading]);

  const tableRows = useMemo(() => {
    const objectFields = ['id', 'id', 'customer_display_name', 'start_date', 'amount', 'monthly_interest_rate', 'term_in_months', 'due'] as Array<keyof Loan>;
    const renderers: RenderersListType[] = [
      {
        field: 'id',
        renderer: value => <CodeNumber value={value} />,
      },
      {
        field: 'start_date',
        renderer: value => <AlignedColumn align="left" value={formatDate(moment(value), 'display_date')} />,
      },
      {
        field: 'amount',
        renderer: value => <NumericColumn value={value} format="money"  />,
      },
      {
        field: 'monthly_interest_rate',
        renderer: value => <AlignedColumn align="right" value={`${value}%`} />,
      },
      {
        field: 'term_in_months',
        renderer: value => <NumericColumn value={value} />,
      },
      {
        field: 'due',
        renderer: value => <NumericColumn value={value} format="money"  />,
      },
    ];
    return getTableRows<Loan>(loans, objectFields, renderers);
  }, [loans]);

  return (
    <MainLayout>
      <BreadCrumbs currentPage="Créditos" />
      {
        isLoading.current ? (
          <Spinner />
        ) : (
          <ListViewTable
            modelName="Contrato"
            formPageUrl={routes.loans.loans.form}
            tableHeaders={tableHeaders.map((th, idx) => ({
              id: idx.toString(),
              sortable: true,
              text: th,
            }))}
            tableRows={tableRows}
            currentPage={currentPage}
            onCurrentPageChange={newPage => setCurrentPage(newPage)}
            rowsPerPage={perPage}
            onRowsPerPageChange={newValue => setPerPage(newValue)}
            totalRecords={totalRecords}
            hasPagination
          />
        )
      }
    </MainLayout>
  );
};

export default LoanList;
