import { AxiosError } from 'axios';

import {
  api, errorResponse, successResponse,
} from './api';
import {
  CreateOrUpdateOneRequestType, GetListRequestType, GetOneRequestType, ResponseWithPagination,
} from './models/BaeModel';
import {
  Customer, CustomerForDailyList, CustomerInstallmentInfo,
} from './models/Customer';
import { getUrlWithPagination, LOAN_ROUTES } from './routes';

// >> CUSTOMER
const createCustomer: CreateOrUpdateOneRequestType<Customer> = async record => {
  try {
    const response = await api.post<Customer>(LOAN_ROUTES.customer.list, record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const updateCustomer: CreateOrUpdateOneRequestType<Customer> = async record => {
  if (!record.id) {
    return { error: 'Record ID is required' };
  }

  try {
    const response = await api.put<Customer>(
      LOAN_ROUTES.customer.single.replace(':id', record.id.toString()),
      record,
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getCustomerList: GetListRequestType<Customer> = async (page, perPage, search, tenantId) => {
  try {
    const response = await api.get<ResponseWithPagination<Customer[]>>(getUrlWithPagination(LOAN_ROUTES.customer.list, page, perPage, undefined, search, { tenant_id: tenantId }));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getCustomer: GetOneRequestType<Customer> = async id => {
  try {
    const response = await api.get<Customer>(LOAN_ROUTES.customer.single.replace(':id', id));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getCustomerDailyList: GetListRequestType<
  CustomerForDailyList
> = async (page, perPage, search, tenantId, extraParams) => {
  try {
    const response = await api.get<
      ResponseWithPagination<CustomerForDailyList[]>
    >(getUrlWithPagination(LOAN_ROUTES.customer.daily_list, page, perPage, undefined, undefined, extraParams));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getCustomerInstallmentInfo: GetOneRequestType<
  CustomerInstallmentInfo
> = async id => {
  try {
    const response = await api.get<CustomerInstallmentInfo>(
      LOAN_ROUTES.customer.oldest_unpaid_installment.replace(':id', id),
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

// << CUSTOMER

export {
  getCustomerList,
  getCustomer,
  createCustomer,
  updateCustomer,
  getCustomerDailyList,
  getCustomerInstallmentInfo,
};
