import React from 'react';

import BaseBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import LinkWrapper from './LinkWrapper';

interface BreadcrumbHistory {
  label: string;
  url: string;
}

interface Props {
  currentPage: string;
  // eslint-disable-next-line react/require-default-props
  history?: BreadcrumbHistory[];
}

const Breadcrumbs: React.FC<Props> = ({ currentPage, history = [] }) => {
  return (
    <BaseBreadcrumbs aria-label="breadcrumb">
      {history.map((h, idx) => (
        <LinkWrapper to={h.url} key={`breadcrumb-element-${idx}`}>
          {h.label}
        </LinkWrapper>
      ))}
      <Typography color="textPrimary">{currentPage}</Typography>
    </BaseBreadcrumbs>
  );
};

export default Breadcrumbs;
