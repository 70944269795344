import React, { PropsWithChildren } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const ContentWrapper = styled(Paper)((props: {isMobile: boolean, wide?: boolean}) => ({
  padding: `3em ${props.isMobile || props.wide ? '' : '25%'}`,
}));

interface Props {
  label: string;
  // eslint-disable-next-line react/require-default-props
  wide?: boolean;
}

const FormContentLayout: React.FC<PropsWithChildren<Props>> = ({
  children, label,wide,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Container>
      <Typography
        variant="h6"
        display="block"
        gutterBottom
      >
        {label}
      </Typography>
      <ContentWrapper elevation={3} isMobile={isMobile} wide={wide}>
        {children}
      </ContentWrapper>
    </Container>
  );
};

export default FormContentLayout;
