import moment from 'moment';

import { BaseModel } from './BaeModel';
import { formatDate } from '../../common/utils';

interface MiscExpense extends BaseModel {
  date: string;                 // Expense date
  concept: string;              // Expense concept description
  amount: number;               // Expense amount
}

const defaultMiscExpenseValues = {
  date: formatDate(moment(), 'api_date'),
  salesman_id: 0,
};

export type { MiscExpense };

export { defaultMiscExpenseValues };
