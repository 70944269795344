import React from 'react';

import Grid from '@material-ui/core/Grid';

import TodayPaymentsSummary from './TodayPaymentsSummary';
import MainLayout from '../layout/MainLayout';

const Dashboard: React.FC = () => {

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TodayPaymentsSummary />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default Dashboard;
