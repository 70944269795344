import React, { useEffect,  useState } from 'react';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

import { formatNumber } from '../../../common/utils';
import MainLayout from '../../layout/MainLayout';

const InputWrapper = styled.div`
  padding: 0 0 1em 0;
`;

interface LoanDataType {
  weeks: number,
  weeklyInterest: number,
  monthlyInterest: number,
  totalInterest: number,
  totalRepayAmount: number,
  weeklyPayment: number,
}

const DEFAULT_MONTHLY_INTEREST = 25;
const MIN_MONTHLY_INTEREST = 15;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
  border-bottom: solid 0.5px #c9c9c9;
`;

const Text = styled.div`
  font-size: 1em;
  font-weight: 400;
`;

const Container = styled(Paper)`
  padding: 10px;
`;

const QuoteForm: React.FC = () => {
  const [amount, setAmount] = useState(3000);
  const [months, setMonths] = useState(1);
  const [monthlyInterest, setMonthlyInterest] = useState(DEFAULT_MONTHLY_INTEREST);
  const [data, setData] = useState({} as LoanDataType);

  useEffect(() => {
    const weeks = months * 4;
    const weeklyInterest = monthlyInterest/4;
    const totalInterest=weeks * weeklyInterest;
    const totalRepayAmount=amount * (1 + totalInterest/100);
    const weeklyPayment = totalRepayAmount / weeks;

    setData({ monthlyInterest, totalInterest, totalRepayAmount, weeklyInterest, weeklyPayment, weeks });
  }, [amount, months, monthlyInterest]);

  return (
    <MainLayout>
      <Container>
        <InputWrapper>
          <TextField
            label="Monto inicial"
            placeholder="Ej: 3000"
            InputLabelProps={{ shrink: Boolean(amount) || amount === 0 }}
            value={amount}
            onChange={e => setAmount(e.target.value !== '' ? parseFloat(e.target.value) : 0)}
            fullWidth
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="Plazo en meses"
            placeholder="Ej: 2"
            value={months}
            onChange={e => setMonths(e.target.value !== '' ? parseFloat(e.target.value) : 0)}
            InputLabelProps={{ shrink: Boolean(amount) || amount === 0 }}
            fullWidth
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="% Interés mensual"
            InputLabelProps={{ shrink: Boolean(amount) || amount === 0 }}
            value={monthlyInterest}
            onChange={e => setMonthlyInterest(e.target.value !== '' ? parseFloat(e.target.value) : MIN_MONTHLY_INTEREST)}
            fullWidth
          />
        </InputWrapper>
        <Row>
          <Text><b>Cuota semanal</b></Text>
          <Text><b>{formatNumber('money', Math.ceil(data?.weeklyPayment ?? 0))}</b></Text>
        </Row>
        <Row>
          <Text>Plazo en semanas</Text>
          <Text>{data?.weeks}</Text>
        </Row>
        <Row>
          <Text>% Interés semanal</Text>
          <Text>{`${data?.weeklyInterest?.toString()} %`}</Text>
        </Row>
        <Row>
          <Text>Pago total</Text>
          <Text>{formatNumber('money', data?.totalRepayAmount ?? 0)}</Text>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default QuoteForm;
