import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  useForm, SubmitHandler, FormProvider, useWatch,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getSalesmenList } from '../../../api/admin';
import { createLoan, getLoan } from '../../../api/loan';
import { Customer, defaultCustomerValues } from '../../../api/models/Customer';
import { defaultLoanValues, Loan } from '../../../api/models/Loan';
import { Salesman } from '../../../api/models/Salesman';
import { API_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../../common/constants';
import routes from '../../../common/routes';
import { formatAsCodeNumber } from '../../../common/utils';
import FormContentLayout from '../../layout/FormContentLayout';
import MainLayout from '../../layout/MainLayout';
import ActionButton from '../../shared/ActionButton';
import BreadCrumbs from '../../shared/BreadCrumbs';
import CustomerSelectModal from '../../shared/CustomerSelectModal';
import FormDateField from '../../shared/FormDateField';
import FormRadioGroupField from '../../shared/FormRadioGroupField';
import FormSelectField from '../../shared/FormSelectField';
import FormTextAreaField from '../../shared/FormTextAreaField';
import FormTextField from '../../shared/FormTextField';
import SectionDivider from '../../shared/SectionDivider';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
`;

const SelectClientContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const bcHistory = [{
  label: 'Contratos',
  url: routes.loans.loans.list,
}];

const LoanForm: React.FC = () => {
  // const { currentTenantId, currentTenant } = useTenant();
  const methods = useForm<Loan>({
    defaultValues: defaultLoanValues,
  });
  const customerIdNumber = useWatch({ control: methods.control, name: 'customer.id_card_number' });
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const recordId = params.id;
  const isEdit = Boolean(recordId && recordId !== 'new');
  const isLoading = useRef<boolean>(false);
  const [hasSpouse, setHasSpouse] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const isLoadingSalesmen = useRef<boolean>(false);
  const [salesmen, setSalesmen] = useState<Salesman[]>([]);

  const fetchSalesmen = useCallback(async () => {
    isLoadingSalesmen.current = true;
    const { data, error } = await getSalesmenList(1, 100);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      setSalesmen(data.records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoadingSalesmen.current = false;
  }, [enqueueSnackbar]);

  const fetch = useCallback(async () => {
    isLoading.current = true;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = await getLoan(recordId!);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      methods.setValue('id', data.id);
      methods.setValue('customer_id', data.customer_id);

    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
  }, [methods, recordId, enqueueSnackbar]);

  useEffect(() => {
    if (isEdit && !isLoading.current) {
      fetch();
    }
  }, [isEdit, isLoading, fetch]);

  useEffect(() => {
    if (customerIdNumber) {
      try {
        const match = customerIdNumber.match(/^(\d{3})-(\d{6})-.*/);
        if (match) {
          const dob = match[2];
          const date_of_birth = moment(dob, 'DDMMyyyy').format(DISPLAY_DATE_FORMAT);
          methods.setValue('customer.date_of_birth', date_of_birth);
        }
      } catch {
        console.log('Número de cédula no válido');
      }
    }
  }, [customerIdNumber]);

  useEffect(() => {
    if (salesmen.length === 0 && !isLoadingSalesmen.current) {
      fetchSalesmen();
    }
  }, [salesmen, isLoadingSalesmen, fetchSalesmen]);

  const onSubmit: SubmitHandler<Loan> = async record => {
    let start_date = '';
    let date_of_birth = '';
    if (typeof record.start_date === 'string') {
      start_date = moment(record.start_date, DISPLAY_DATE_FORMAT).format(API_DATE_FORMAT);
    } else {
      start_date = (record.start_date as moment.Moment).format(API_DATE_FORMAT);
    }

    if (record.customer?.date_of_birth && typeof record.customer?.date_of_birth === 'string') {
      date_of_birth = moment(record.customer.date_of_birth, DISPLAY_DATE_FORMAT).format(API_DATE_FORMAT);
    } else if (record.customer?.date_of_birth) {
      date_of_birth = (record.customer.date_of_birth as moment.Moment).format(API_DATE_FORMAT);
    }

    const loan = { ...record, start_date } as Loan;
    if (loan.customer?.date_of_birth) {
      loan.customer.date_of_birth = date_of_birth;
    }

    if (isEdit) {
      // const { error } = await updateLoan(loan);
      // if (error) {
      //   enqueueSnackbar(error, { variant: 'error' });
      // } else {
      //   enqueueSnackbar('Registro actualizado.', { variant: 'success' });
      //   navigate(routes.loans.loans.list);
      // }
      return;
    }

    if (!loan.salesman_id || loan.salesman_id === 0) {
      enqueueSnackbar('Debe seleccionar un Gestor de Cobro', { variant: 'error' });
      return;
    }

    const { error } = await createLoan(loan);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      console.log(error);
      enqueueSnackbar('Registro creado', { variant: 'success' });
      navigate(routes.loans.loans.list);
    }
  };

  const onCustomerChange = (customer?: Customer) => {
    if (customer) {
      methods.setValue('customer_id', customer.id!);
      methods.setValue('customer', { ...customer, date_of_birth: moment(customer.date_of_birth, API_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) });
    } else {
      methods.setValue('customer_id', 0);
      methods.setValue('customer', { ...defaultCustomerValues } as Customer);
    }
  };

  const handleModalClose = (customer: Customer | undefined) => {
    setShowModal(false);
    onCustomerChange(customer);
  };

  return (
    <MainLayout>
      <CustomerSelectModal open={showModal} onClose={customer => handleModalClose(customer)} />
      <BreadCrumbs currentPage={isEdit ? 'Editar' : 'Crear'} history={bcHistory} />
      <FormContentLayout
        label={`${isEdit ? '' : 'Nuevo'} Crédito ${recordId && !isNaN(parseFloat(recordId)) ? formatAsCodeNumber(recordId) : ''}`.trim()}
        wide
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {/*<input {...methods.register('tenant_id')} value={currentTenantId} type="hidden"/>*/}
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormDateField name="start_date" label="Fecha" required todayButton />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="No. de Crédito"
                  placeholder="Auto generado"
                  fullWidth
                  value="Auto Generado"
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectClientContainer>
                  <Button variant="contained" color="primary" onClick={() => setShowModal(true)}>Seleccionar Cliente</Button>
                  <IconButton color="primary" onClick={() => onCustomerChange(undefined)}><CancelIcon /></IconButton>
                </SelectClientContainer>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormSelectField
                  name="salesman_id"
                  label="Promotor"
                  options={salesmen.map(sm => ({ label: `${sm.first_name_1} ${sm.lastname_1}`, value: sm.id?.toString() ?? '' }))}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormSelectField
                  name="salesman_id"
                  label="Gestor de Cobro"
                  options={salesmen.map(sm => ({ label: `${sm.first_name_1} ${sm.lastname_1}`, value: sm.id?.toString() ?? '' }))}
                />
              </Grid>
            </Grid>

            <SectionDivider>
              <Typography component="h6" variant="h6">Datos del Cliente</Typography>
            </SectionDivider>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.first_name_1"
                  label="Primer Nombre"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.first_name_2"
                  label="Segundo Nombre"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.lastname_1"
                  label="Primer Apellido"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.lastname_2"
                  label="Segundo Apellido"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormRadioGroupField name="customer.id_card_type" label="Tipo de Identificación" options={[
                  { label: 'Cédula', value: 'id_card' },
                  { label: 'Pasaporte', value: 'passport' },
                ]} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.id_card_number"
                  label="Número de Identificación"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <FormTextField
                  name="customer.nationality"
                  label="Nacionalidad"
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormRadioGroupField name="customer.gender" label="Sexo" options={[
                  { label: 'M', value: 'male' },
                  { label: 'F', value: 'female' },
                ]} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormRadioGroupField name="customer.home_ownership_status" label="Vivienda" options={[
                  { label: 'Propia', value: 'own_home' },
                  { label: 'Familiar', value: 'family_owned' },
                  { label: 'Alquila', value: 'rent' },
                ]} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormDateField name="customer.date_of_birth" label="Fecha de Nacimiento" required />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.academic_status"
                  label="Nivel Académico"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormTextAreaField
                  name="customer.home_address"
                  label="Dirección Domiciliar"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.city"
                  label="Departamento"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.municipality"
                  label="Municipio"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.phone_number"
                  label="Teléfono del Domicilio"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.cellphone_number"
                  label="Celular"
                  required
                />
              </Grid>
            </Grid>

            <SectionDivider>
              <Typography component="h6" variant="h6">Datos del Cónyuge</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={hasSpouse}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setHasSpouse(event.target.checked)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label=""
              />
            </SectionDivider>
            {hasSpouse && (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="customer.spouse.first_name_1"
                      label="Primer Nombre"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="customer.spouse.first_name_2"
                      label="Segundo Nombre"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="customer.spouse.lastname_1"
                      label="Primer Apellido"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="customer.spouse.lastname_2"
                      label="Segundo Apellido"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormRadioGroupField name="customer.spouse.id_card_type" label="Tipo de Identificación" options={[
                      { label: 'Cédula', value: 'id_card' },
                      { label: 'Pasaporte', value: 'passport' },
                    ]} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="customer.spouse.id_card_number"
                      label="Número de Identificación"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="customer.spouse.phone_number"
                      label="Teléfono del Domicilio"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name="customer.spouse.cellphone_number"
                      label="Celular"
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <SectionDivider>
              <Typography component="h6" variant="h6">Información del Negocio</Typography>
            </SectionDivider>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormTextField
                  name="customer.business_info.economic_activity"
                  label="Actividad Económica"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormTextAreaField
                  name="customer.business_info.address"
                  label="Dirección del Negocio"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.business_info.city"
                  label="Departamento"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="customer.business_info.municipality"
                  label="Municipio"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <FormTextField
                  name="customer.business_info.inventory_value"
                  label="Inventario"
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormTextField
                  name="customer.business_info.cash"
                  label="Efectivo"
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormTextField
                  name="customer.business_info.max_expected_daily_income"
                  label="Día Bueno"
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormTextField
                  name="customer.business_info.min_expected_daily_income"
                  label="Dia Malo"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="customer.business_info.years_of_experience"
                  label="Experiencia"
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="customer.business_info.business_age"
                  label="Antigüedad"
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="customer.business_info.collaborators_count"
                  label="No. de Trabajadores"
                  required
                />
              </Grid>
            </Grid>
            <SectionDivider>
              <Typography component="h6" variant="h6">Datos del Crédito Solicitado</Typography>
            </SectionDivider>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <FormTextField
                  name="amount"
                  label="Monto Solicitado"
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormTextField
                  name="term_in_months"
                  label="Plazo (en meses)"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormRadioGroupField name="payment_period" label="Forma de Pago" options={[
                  { label: 'Semanal', value: 'weekly' },
                  { label: 'Quincenal', value: 'biweekly' },
                  { label: 'Mensual', value: 'monthly' },
                ]} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <FormTextField
                  name="loan_purpose"
                  label="Uso del Préstamo"
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormTextField
                  name="monthly_interest_rate"
                  label="Tasa de Interés Mensual"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormTextField
                  name="external_active_loan_entity_name"
                  label="Crédito con Otra Institución"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormTextField
                  name="external_active_loan_amount"
                  label="Monto"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormTextField
                  name="external_active_loan_term_in_months"
                  label="Plazo (en meses)"
                />
              </Grid>
            </Grid>

            <SectionDivider>
              <Typography component="h6" variant="h6">Referencias Personales</Typography>
            </SectionDivider>

            <SectionDivider>
              <Typography component="h6" variant="h6">Recomendaciones</Typography>
            </SectionDivider>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormTextAreaField
                  name="observations"
                  label="Recomendaciones"
                />
              </Grid>
            </Grid>
            <ActionsWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => navigate(-1)}
                className="action-button"
              >
                Cancelar
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                className="action-button"
              >
                Guardar
              </ActionButton>
            </ActionsWrapper>
          </form>
        </FormProvider>
      </FormContentLayout>
    </MainLayout>
  );
};

export default LoanForm;
