import React, {
  createContext, useEffect, useState, useContext, PropsWithChildren,
} from 'react';

import { Tenant } from '../api/models/Tenant';
import { getTenantList } from '../api/tenants';
import { Consumer, Factory } from '../common/types';

export interface TenantContextType {
  currentTenant?: Tenant;
  currentTenantId: number;
  setCurrentTenantId: Consumer<number>;
  tenants: Tenant[];
}

const TenantContext = createContext<TenantContextType>({
  currentTenant: undefined,
  currentTenantId: 1,
  setCurrentTenantId: () => null,
  tenants: [],
});

const DEFAULT_TENANT_ID = 1;

const useProvidedTenant: Factory<TenantContextType> = () => {
  const [tenantId, setTenantId] = useState(DEFAULT_TENANT_ID);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [currentTenant, setCurrentTenant] = useState<Tenant | undefined>(undefined);

  const setTenant: Consumer<number> = id => {
    setTenantId(id);
    setCurrentTenant(tenants.find(t => t.id === id));
  };

  useEffect(() => {
    const fetch = async () => {
      const { data, error } = await getTenantList();
      if (error) {
        console.log('Error loading tenants', error);
      } else if (data) {
        setTenants(data.records);
        setCurrentTenant(data.records.find(t => t.id === DEFAULT_TENANT_ID));
      } else {
        console.log('No se encontraron tenants');
      }
    };
    if (tenants.length === 0) {
      fetch();
    }
  }, [tenants]);

  return {
    currentTenant,
    currentTenantId: tenantId,
    setCurrentTenantId: id => setTenant(id),
    tenants,
  };
};

const TenantProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const tenant = useProvidedTenant();
  return <TenantContext.Provider value={tenant}>{children}</TenantContext.Provider>;
};

const useTenant: Factory<TenantContextType> = () => useContext(TenantContext);

export { TenantContext, TenantProvider, useTenant };
