import { api } from './api';
import { AUTH_ROUTES } from './routes';

// eslint-disable-next-line no-unused-vars
const login: (email: string, password: string) => Promise<{token?: string, error?: string}> = async (email, password) => {
  const data = { email, password };
  try {
    const response = await api.post(AUTH_ROUTES.login, data);
    return { token: response.data.token };
  } catch (error) {
    return { error: (error as Error).message };
  }
};

export { login as loginRequest };
