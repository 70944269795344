import moment from 'moment/moment';

import { BaseModel } from './BaeModel';
import { formatDate } from '../../common/utils';

interface SalesmanGasExpense extends BaseModel {
  salesman_id: number;  // Foreign key reference to Salesman
  date: string;         // Expense date (string format)
  amount: number;       // Gas expense amount
  salesman_full_name?: string;
}

const defaultSalesmanGasExpenseValues = {
  date: formatDate(moment(), 'api_date'),
  salesman_id: 0,
};

export type { SalesmanGasExpense };

export { defaultSalesmanGasExpenseValues };
