import React, {
  useCallback, useEffect, useRef,
} from 'react';

import { useSnackbar } from 'notistack';
import {
  useForm, SubmitHandler, FormProvider,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  createSalesman, getSalesman, updateSalesman,
} from '../../../api/admin';
import { Salesman } from '../../../api/models/Salesman';
import routes from '../../../common/routes';
import FormContentLayout from '../../layout/FormContentLayout';
import MainLayout from '../../layout/MainLayout';
import ActionButton from '../../shared/ActionButton';
import BreadCrumbs from '../../shared/BreadCrumbs';
import FormTextField from '../../shared/FormTextField';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
`;

const bcHistory = [{
  label: 'Gestores',
  url: routes.admin.salesmen.list,
}];

const SalesmanForm: React.FC = () => {
  const methods = useForm<Salesman>();
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const recordId = params.id;
  const isEdit = Boolean(recordId && recordId !== 'new');
  const isLoading = useRef<boolean>(false);

  const fetch = useCallback(async () => {
    isLoading.current = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = await getSalesman(recordId!);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      methods.setValue('id', data.id);
      methods.setValue('first_name_1', data.first_name_1);
      methods.setValue('first_name_2', data.first_name_2);
      methods.setValue('lastname_1', data.lastname_1);
      methods.setValue('lastname_2', data.lastname_2);
      methods.setValue('cellphone_number', data.cellphone_number);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
  }, [methods, recordId, enqueueSnackbar]);

  useEffect(() => {
    if (isEdit && !isLoading.current) {
      fetch();
    }
  }, [isEdit, isLoading, fetch]);

  const onSubmit: SubmitHandler<Salesman> = async record => {
    if (isEdit) {
      const { error } = await updateSalesman(record);
      if (error) {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        enqueueSnackbar('Registro actualizado.', { variant: 'success' });
        navigate(routes.admin.salesmen.list);
      }
      return;
    }

    const { error } = await createSalesman(record);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      enqueueSnackbar('Registro creado', { variant: 'success' });
      navigate(routes.admin.salesmen.list);
    }
  };

  return (
    <MainLayout>
      <BreadCrumbs currentPage={isEdit ? 'Editar' : 'Crear'} history={bcHistory} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormContentLayout label="Gestores">
            <FormTextField
              name="first_name_1"
              label="Primer nombre"
              required
            />
            <FormTextField
              name="first_name_2"
              label="Segundo nombre"
              required
            />
            <FormTextField
              name="lastname_1"
              label="Primer apellido"
              required
            />
            <FormTextField
              name="lastname_2"
              label="Segundo apellido"
              required
            />
            <FormTextField
              name="cellphone_number"
              label="Celular"
              required
            />
            <ActionsWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => navigate(-1)}
                className="action-button"
              >
              Cancelar
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                className="action-button"
              >
              Guardar
              </ActionButton>
            </ActionsWrapper>
          </FormContentLayout>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default SalesmanForm;
