import { AxiosError } from 'axios';

import {
  api, errorResponse, successResponse,
} from './api';
import { GetListRequestType, ResponseWithPagination } from './models/BaeModel';
import { Tenant } from './models/Tenant';
import { getUrlWithPagination, TENANT_ROUTES } from './routes';

const getTenantList: GetListRequestType<Tenant> = async (page, perPage) => {
  try {
    const response = await api.get<ResponseWithPagination<Tenant[]>>(getUrlWithPagination(TENANT_ROUTES.tenant.list, page, perPage));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

export {
  getTenantList,
};
