import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { useSnackbar } from 'notistack';
import {
  useForm, SubmitHandler, FormProvider,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getSalesmenList } from '../../../api/admin';
import {
  createSalesmanGasExpense, getSalesmanGasExpense, updateSalesmanGasExpense,
} from '../../../api/dailyOperations';
import { getPersonShortName } from '../../../api/models/Person';
import { Salesman } from '../../../api/models/Salesman';
import { defaultSalesmanGasExpenseValues, SalesmanGasExpense } from '../../../api/models/SalesmanGasExpense';
import routes from '../../../common/routes';
import FormContentLayout from '../../layout/FormContentLayout';
import MainLayout from '../../layout/MainLayout';
import ActionButton from '../../shared/ActionButton';
import BreadCrumbs from '../../shared/BreadCrumbs';
import FormDateField from '../../shared/FormDateField';
import FormSelectField from '../../shared/FormSelectField';
import FormTextField from '../../shared/FormTextField';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
`;

const bcHistory = [{
  label: 'Gastos de Gasolina de Gestores',
  url: routes.dailyOperations.salesmenGasExpenses.list,
}];

const SalesmanGasExpenseForm: React.FC = () => {
  const methods = useForm<SalesmanGasExpense>({ defaultValues: defaultSalesmanGasExpenseValues });
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const recordId = params.id;
  const isEdit = Boolean(recordId && recordId !== 'new');
  const isLoading = useRef<boolean>(false);
  const isLoadingSalesmen = useRef<boolean>(false);
  const [salesmen, setSalesmen] = useState<Salesman[]>([]);

  const fetchSalesmen = useCallback(async () => {
    isLoadingSalesmen.current = true;
    const { data, error } = await getSalesmenList(1, 100);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      setSalesmen(data.records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoadingSalesmen.current = false;
  }, [enqueueSnackbar]);

  const fetch = useCallback(async () => {
    isLoading.current = true;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data, error } = await getSalesmanGasExpense(recordId!);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    } else if (data) {
      methods.setValue('id', data.id);
      methods.setValue('salesman_id', data.salesman_id);
      methods.setValue('date', data.date);
      methods.setValue('amount', data.amount);
      methods.setValue('salesman_full_name', data.salesman_full_name);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
  }, [methods, recordId, enqueueSnackbar]);

  useEffect(() => {
    if (isEdit && !isLoading.current) {
      fetch();
    }
  }, [isEdit, isLoading, fetch]);

  useEffect(() => {
    if (salesmen.length === 0 && !isLoadingSalesmen.current) {
      fetchSalesmen();
    }
  }, [salesmen, isLoadingSalesmen, fetchSalesmen]);

  const onSubmit: SubmitHandler<SalesmanGasExpense> = async record => {
    if (isEdit) {
      const { error } = await updateSalesmanGasExpense(record);
      if (error) {
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        enqueueSnackbar('Registro actualizado.', { variant: 'success' });
        navigate(routes.dailyOperations.salesmenGasExpenses.list);
      }
      return;
    }

    const { error } = await createSalesmanGasExpense(record);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else {
      enqueueSnackbar('Registro creado', { variant: 'success' });
      navigate(routes.dailyOperations.salesmenGasExpenses.list);
    }
  };

  return (
    <MainLayout>
      <BreadCrumbs currentPage={isEdit ? 'Editar' : 'Crear'} history={bcHistory} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormContentLayout label="Gasto de Gasolina de Gestor">
            <FormSelectField
              name="salesman_id"
              label="Gestor de Cobro"
              options={salesmen.map(sm => ({ label: getPersonShortName(sm), value: sm.id?.toString() ?? '' }))}
            />
            <FormDateField name="date" label="Fecha" todayButton/>
            <FormTextField
              name="amount"
              label="Monto"
              required
            />
            <ActionsWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => navigate(-1)}
                className="action-button"
              >
              Cancelar
              </ActionButton>
              <ActionButton
                variant="contained"
                color="primary"
                onClick={methods.handleSubmit(onSubmit)}
                className="action-button"
              >
              Guardar
              </ActionButton>
            </ActionsWrapper>
          </FormContentLayout>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default SalesmanGasExpenseForm;
