import moment from 'moment';

import { BaseModel } from './BaeModel';
import { BusinessInfo } from './BusinessInfo';
import { Customer, defaultCustomerValues } from './Customer';
import { DISPLAY_DATE_FORMAT } from '../../common/constants';

interface Loan extends BaseModel {
  customer_id: number;
  salesman_id: number;
  business_info_id: number;
  start_date: string | moment.Moment;
  end_date: string;
  amount: number;
  term_in_months: number;
  payment_period: number;
  customer_has_external_active_loan?: boolean;
  external_active_loan_entity_name?: string;
  external_active_loan_amount?: number;
  external_active_loan_term_in_months?: number;
  loan_purpose: string;
  observations?: string;
  monthly_interest_rate: number;
  payment_status: number;
  investment_recovered: boolean;
  customer?: Customer
  business_info?: Partial<BusinessInfo>
}

export const defaultLoanValues = {
  amount: 0,
  // business_info: defaultBusinessInfo,
  customer: defaultCustomerValues,
  customer_id: 0,
  loan_purpose: '',
  monthly_interest_rate: 25,
  payment_period: 0,
  salesman_id: 0,
  start_date: moment().format(DISPLAY_DATE_FORMAT),
  term_in_months: 1,
  // customer_has_external_active_loan?: boolean;
  // external_active_loan_entity_name?: string;
  // external_active_loan_amount?: number;
  // external_active_loan_term_in_months?: number;
  // observations?: string;
};

export type { Loan };
