import React, { useEffect, useState } from 'react';

import { Button,  TablePagination } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import LinkWrapper from './LinkWrapper';
import { COLORS, PAGINATION_PER_PAGE_OPTIONS } from '../../common/constants';
import { Consumer } from '../../common/types';

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  padding-bottom: 15px;
`;

const StyledTable = styled(Table)`
  width: 100%;
`;

const HeaderCell = styled(TableCell)`
  font-weight: bold;
`;

const StyledTableRow = styled(TableRow)<{selected: boolean, selectable?: boolean}>`
  ${props => props.selected ? `background-color: ${COLORS.silver};` : ''}
  ${props => props.selectable ? 'cursor: pointer;' : ''}
  &:hover {
    background-color: ${COLORS.silver};
  }
`;

const SelectableCell = styled.div``;

const SearchFieldContainer = styled.div`
  padding: 0 0 1em 1em;
`;

interface TableHeaderType {
  id: string;
  text: string | React.ReactNode;
  sortable: boolean;
}

interface TableCellType {
  id: string;
  text: string;
  // eslint-disable-next-line no-unused-vars
  render?: (value: string | number) => React.ReactNode;
  // eslint-disable-next-line no-unused-vars
  actions?: <T>(value: T) => React.ReactNode;
}

interface TableRowType {
  id: string;
  cells: TableCellType[];
}

interface Props {
  modelName: string;
  tableHeaders: TableHeaderType[];
  tableRows: TableRowType[];
  currentPage: number;
  totalRecords: number;
  formPageUrl: string;
  // eslint-disable-next-line react/require-default-props
  searchLabel?: string;
  // eslint-disable-next-line react/require-default-props
  searchPlaceholder?: string;
  // eslint-disable-next-line react/require-default-props
  hasPagination?: boolean;
  // eslint-disable-next-line react/require-default-props
  rowsPerPage?: number;
  // eslint-disable-next-line react/require-default-props
  onCurrentPageChange?: Consumer<number>;
  // eslint-disable-next-line react/require-default-props
  onRowsPerPageChange?: Consumer<number>;
  // eslint-disable-next-line react/require-default-props
  noAddButton?: boolean;
  // eslint-disable-next-line react/require-default-props
  selectable?: boolean;
  // eslint-disable-next-line no-unused-vars,react/require-default-props
  onRowSelect?: (record: string) => void;
  // eslint-disable-next-line no-unused-vars,react/require-default-props
  hasActions?: boolean;
  // eslint-disable-next-line no-unused-vars,react/require-default-props
  onSearch?: Consumer<string>;
  // eslint-disable-next-line no-unused-vars,react/require-default-props
  searchValue?: string;
  // eslint-disable-next-line no-unused-vars,react/require-default-props
  customActions?: React.ReactNode;
}

export type RenderersListType = {
  field: string;
  // eslint-disable-next-line no-unused-vars
  renderer: (value: string | number) => React.ReactNode;
}

const getTableRows: <T>(
  // eslint-disable-next-line no-unused-vars
  records: T[],
  // eslint-disable-next-line no-unused-vars
  fields: Array<keyof T>,
  // eslint-disable-next-line no-unused-vars
  renderers?: RenderersListType[],
  // eslint-disable-next-line no-unused-vars
  hasActions?: boolean,
) => TableRowType[] = (records, fields, renderers, hasActions= false) => {
  const idField = fields.shift();
  if (!idField) {
    return [] as TableRowType[];
  }
  return records.map(record => {
    const cells = fields.map(field => ({
      id: field.toString(),
      render: renderers?.find(r => r.field === field)?.renderer,
      text: record[field] as string,
    }));

    if (hasActions) {
      cells.push({
        id: '_actions',
        render: renderers?.find(r => r.field === '_actions')?.renderer,
        text: JSON.stringify(record),
      });
    }

    return ({
      cells,
      id: record[idField],
    } as TableRowType);
  });
};

const ListViewTable: React.FC<Props> = ({
  modelName,
  tableHeaders,
  tableRows,
  currentPage,
  formPageUrl,
  hasPagination = false,
  rowsPerPage = 10,
  onCurrentPageChange,
  onRowsPerPageChange,
  totalRecords,
  noAddButton,
  selectable,
  onRowSelect,
  hasActions,
  searchLabel = 'Buscar',
  searchValue= '',
  searchPlaceholder = '',
  onSearch,
  customActions,
}) => {
  const [selectedRowId, setSelectedRowId] = useState<number | undefined>(undefined);
  const emptyRows = rowsPerPage - tableRows.length;
  const [internalSearchValue, setInternalSearchValue] = useState(searchValue);
  const [searchQry] = useDebounce(internalSearchValue, 350);

  useEffect(() => {
    if (onSearch) {
      onSearch(searchQry);
    }
  }, [onSearch, searchQry]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setSelectedRowId(undefined);
    if (onCurrentPageChange) {
      onCurrentPageChange(newPage +1);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRowId(undefined);
    if (onRowsPerPageChange && onCurrentPageChange) {
      onRowsPerPageChange(parseInt(event.target.value, 10));
      onCurrentPageChange(1);
    }
  };

  const handleRowSelect = (rowId: string, rowIndex: number) => {
    setSelectedRowId(rowIndex);
    if (onRowSelect) {
      onRowSelect(rowId);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <SearchFieldContainer>
            <TextField
              label={searchLabel}
              placeholder={searchPlaceholder}
              fullWidth
              value={internalSearchValue}
              onChange={e => setInternalSearchValue(e.target.value)}
            />
          </SearchFieldContainer>
        </Grid>
        <Grid item xs={6} md={9}>
          <ActionsContainer>
            { !noAddButton && (
              <LinkWrapper to={formPageUrl.replace(':id', 'new')}>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<AddIcon />}
                >
                Agregar {modelName}
                </Button>
              </LinkWrapper>
            )}
            { Boolean(customActions) && customActions}
          </ActionsContainer>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <StyledTable aria-label={`${modelName} table`}>
          <TableHead>
            <TableRow>
              {tableHeaders.map(th => (
                <HeaderCell key={`th-${th.id}`}>{th.text}</HeaderCell>
              ))}
              {hasActions && <HeaderCell className="_actions actions-header" />}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map((row, idx) =>
              <StyledTableRow
                key={`row-${row.id}`}
                selected={idx === selectedRowId}
                selectable={selectable}
              >
                {row.cells.map(cell => {
                  const LinkCell = <LinkWrapper to={formPageUrl.replace(':id', row.id)}>{cell.render ? cell.render(cell.text) : cell.text}</LinkWrapper>;
                  const SelectCell = (
                    <SelectableCell
                      key={`cell-${row.id}-${cell.id}`}
                      onClick={() => handleRowSelect(row.id, idx)}
                    >
                      {cell.render ? cell.render(cell.text) : cell.text}
                    </SelectableCell>
                  );
                  const cellId = `cell-${row.id}-${cell.id}`;
                  const renderCell = () => {
                    if (cell.id === '_actions' && cell.render) {
                      return cell.render(cell.text);
                    }
                    return selectable ? SelectCell : LinkCell;
                  };
                  return (
                    <TableCell key={cellId} id={cellId} className={cell.id === '_actions' ? '_actions' : ''}>
                      {renderCell()}
                    </TableCell>
                  );
                },
                )}
              </StyledTableRow>,
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: (53) * emptyRows }}>
                <TableCell colSpan={tableHeaders.length} />
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      { hasPagination && (
        <TablePagination
          rowsPerPageOptions={PAGINATION_PER_PAGE_OPTIONS}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Mostrar"
          labelDisplayedRows={({
            from, to, count,
          }) =>`${from}-${to} de ${count !== -1 ? count : `'más de' ${to}`}`}
        />
      )}
    </>
  );
};

export { getTableRows };

export default ListViewTable;
