import React, { PropsWithChildren } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const Container = styled(Paper)`
  padding: 1em;
  position: relative;
`;

const Label = styled(Typography)`
  margin-bottom: 1em;
`;

interface Props {
  label: string;
}

const DashboardGroup: React.FC<PropsWithChildren<Props>> = ({ label, children }) => {
  return (
    <Container>
      <Label variant="h5">{label}</Label>
      {children}
    </Container>
  );
};

export default DashboardGroup;
