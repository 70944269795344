import React from 'react';

import './App.css';
import DateFnsUtils from '@date-io/moment';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';

import { COLORS } from './common/constants';
import AppRoutes from './components/routes/AppRoutes';
import { AuthProvider } from './contexts/useAuth';
import { TenantProvider, useTenant } from './contexts/useTenant';

// const theme = createTheme({
//   palette: {
//     error: {
//       main: COLORS.red,
//     },
//     primary: {
//       main: COLORS.bgGradientColorAverage,
//     },
//   },
// });

const Providers: React.FC = () => {
  const { currentTenantId } = useTenant();

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          error: {
            main: COLORS.red,
          },
          primary: {
            main: currentTenantId === 1 ? COLORS.bgGradientColorAverage : COLORS.clearDay,
          },
        },
      }),
    [currentTenantId],
  );

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <AppRoutes />
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

const App:React.FC = () => (
  <BrowserRouter>
    <TenantProvider>
      <Providers />
    </TenantProvider>
  </BrowserRouter>
);

export default App;
