const COLORS = {
  // bgGradientColor1: '#2F2A3B',
  bgGradientColor1: '#1a0931',
  // bgGradientColor2: '#615F69',
  bgGradientColor2: '#200b39',
  // bgGradientColorAverage: '#4A4754',
  bgGradientColorAverage: '#1a0931',
  clearDay: '#2c9cc2',
  logoColor1: '#896131',
  logoColor2: '#faf3a7',
  porcelain: '#e6e9ea',
  red: '#a12525',
  silver: '#cbcbcb',
  stockAlert: '#b76f49',
};

const THEME = {
  stock: {
    alert: COLORS.stockAlert,
    outOfStock: COLORS.red,
  },
  uiElements: {
    background: COLORS.porcelain,
    borders: COLORS.silver,
    error: COLORS.red,
  },
};

const RECORDS_PAGINATION = {
  page: 1,
  perPage: 10,
};

const PROMOTIONS = {
  FREE_SHIPPING: {
    additionalItemDiscount: 50,
    standardShippingPrice: 80,
  },
};

const BRANDING = {
  facebookPageUrl: '/tiendasellasyellos2',
  whatsAppNumber: '8392 9629',
};

const DISPLAY_DATE_FORMAT = 'DD/MMM/yyyy';
const DISPLAY_TIME_FORMAT = 'hh:mm a';
const DISPLAY_DATE_TIME_FORMAT = `${DISPLAY_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`;
const API_DATE_FORMAT = 'yyyy-MM-DD';

const USD_EXCHANGE_RATE = 36.5;
const BILL_COUNT_HELPER_DETAILS_BILLS_OPTIONS = [1000, 500, 200, 100, 50, 20, 10, 5];
const BILL_COUNT_HELPER_DETAILS_COINS_OPTIONS = [5, 1, 0.5];
const BILL_COUNT_HELPER_DETAILS_USD_BILLS_OPTIONS = [100, 50, 20, 10, 5, 2, 1];

const PAGINATION_PER_PAGE_OPTIONS = [5, 10, 15, 25, 50, 100, 500];

const RE_INVESTMENT_PERCENTAGE = 0.5;


export {
  COLORS,
  RECORDS_PAGINATION,
  THEME,
  PROMOTIONS,
  BRANDING,
  DISPLAY_DATE_FORMAT,
  API_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  DISPLAY_DATE_TIME_FORMAT,
  PAGINATION_PER_PAGE_OPTIONS,
  USD_EXCHANGE_RATE,
  BILL_COUNT_HELPER_DETAILS_BILLS_OPTIONS,
  BILL_COUNT_HELPER_DETAILS_COINS_OPTIONS,
  BILL_COUNT_HELPER_DETAILS_USD_BILLS_OPTIONS,
  RE_INVESTMENT_PERCENTAGE,
};
