import { BaseModel } from './BaeModel';
import { GenericObjectType } from '../../common/types';

interface User extends BaseModel {
  name: string;
  username: string;
  email: string;
  role: string;
  password: string;
  salesman_id: number;
}

const userRoles: GenericObjectType = {
  admin: 'Administrador',
  salesman: 'Gestor de Cobro',
};

const defaultUserValues = {
  role: userRoles['salesman'],
  salesman_id: 0,
};

export type { User };

export { defaultUserValues, userRoles };
