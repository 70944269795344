import React from 'react';

import {
  Routes,
  Route,
} from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import routes from '../../common/routes';
import EmployeeForm from '../admin/employees/EmployeeForm';
import EmployeeList from '../admin/employees/EmployeeList';
import SalesmanConciliationForm from '../admin/salesmen/SalesmanConciliationForm';
import SalesmanForm from '../admin/salesmen/SalesmanForm';
import SalesmanList from '../admin/salesmen/SalesmanList';
import UserForm from '../admin/users/UserForm';
import UserList from '../admin/users/UserList';
import MiscExpenseForm from '../daily_operations/misc_expenses/MiscExpenseForm';
import MiscExpensesList from '../daily_operations/misc_expenses/MiscExpensesList';
import SalesmanGasExpenseForm from '../daily_operations/salesmen_gas_expenses/SalesmanGasExpenseForm';
import SalesmenGasExpensesList from '../daily_operations/salesmen_gas_expenses/SalesmenGasExpensesList';
import Dashboard from '../dashboard/Dashboard';
import LoanForm from '../loans/loan/LoanForm';
import LoanList from '../loans/loan/LoanList';
import QuoteForm from '../loans/quotes/QuoteForm';
import Login from '../login/Login';
import NotFoundPage from '../not_found/NotFoundPage';
import PaymentList from '../payments/PaymentList';
import MonthlyClosingForm from '../reports/monthly_closings/MonthlyClosingForm';
import MonthlyClosingList from '../reports/monthly_closings/MonthlyClosingList';
import CustomerDailyList from '../sales/customer/CustomerDailyList';
import CustomerForm from '../sales/customer/CustomerForm';
import CustomerList from '../sales/customer/CustomerList';
import PaymentForm from '../sales/customer/PaymentForm';


const AppRoutes:React.FC = () => {

  return (
    <Routes>
      <Route path={routes.dashboard} element={<PrivateRoute><Dashboard /></PrivateRoute>} />
      <Route path={routes.loans.quote} element={<PrivateRoute><QuoteForm /></PrivateRoute>} />
      <Route path={routes.loans.loans.list} element={<PrivateRoute><LoanList /></PrivateRoute>} />
      <Route path={routes.loans.loans.form} element={<PrivateRoute><LoanForm /></PrivateRoute>} />
      <Route path={routes.loans.dailyCustomerList} element={<PrivateRoute><CustomerDailyList /></PrivateRoute>} />
      <Route path={routes.customer.list} element={<PrivateRoute><CustomerList /></PrivateRoute>} />
      <Route path={routes.customer.form} element={<PrivateRoute><CustomerForm /></PrivateRoute>} />
      <Route path={routes.customer.paymentForm} element={<PrivateRoute><PaymentForm /></PrivateRoute>} />
      <Route path={routes.payments.list} element={<PrivateRoute><PaymentList /></PrivateRoute>} />
      <Route path={routes.admin.users.list} element={<PrivateRoute><UserList /></PrivateRoute>} />
      <Route path={routes.admin.employees.form} element={<PrivateRoute><EmployeeForm /></PrivateRoute>} />
      <Route path={routes.admin.employees.list} element={<PrivateRoute><EmployeeList /></PrivateRoute>} />
      <Route path={routes.admin.salesmen.list} element={<PrivateRoute><SalesmanList /></PrivateRoute>} />
      <Route path={routes.admin.users.form} element={<PrivateRoute><UserForm /></PrivateRoute>} />
      <Route path={routes.admin.salesmen.form} element={<PrivateRoute><SalesmanForm /></PrivateRoute>} />
      <Route path={routes.admin.salesmen.conciliate} element={<PrivateRoute><SalesmanConciliationForm /></PrivateRoute>} />
      <Route path={routes.reports.monthly_closings.form} element={<PrivateRoute><MonthlyClosingForm /></PrivateRoute>} />
      <Route path={routes.reports.monthly_closings.list} element={<PrivateRoute><MonthlyClosingList /></PrivateRoute>} />
      <Route path={routes.dailyOperations.miscExpenses.form} element={<PrivateRoute><MiscExpenseForm /></PrivateRoute>} />
      <Route path={routes.dailyOperations.miscExpenses.list} element={<PrivateRoute><MiscExpensesList /></PrivateRoute>} />
      <Route path={routes.dailyOperations.salesmenGasExpenses.form} element={<PrivateRoute><SalesmanGasExpenseForm /></PrivateRoute>} />
      <Route path={routes.dailyOperations.salesmenGasExpenses.list} element={<PrivateRoute><SalesmenGasExpensesList /></PrivateRoute>} />
      <Route path={routes.login} element={<Login />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
