import styled from 'styled-components';

const SectionDivider = styled.div`
  display: flex;
  padding: 2em 0 0 0;
  margin-bottom: 0.5em;
  border-bottom: solid 1px;

  .MuiTypography-h6 {
    font-weight: bold;
    text-transform: uppercase;
  }

  .MuiFormControlLabel-root {
    margin: -3px 0 0 10px;
  }
`;

export default SectionDivider;
