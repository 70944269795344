import React from 'react';

import styled from 'styled-components';

import NotFoundBg from '../../assets/images/404-bg-1.webp';
import MainLayout from '../layout/MainLayout';

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const Body = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  font-family: 'Courier New', monospace;
  font-size: 30em;
  color: #FFF;
  background: #c3c3c34d;
  border-radius: 15px;
`;

const NotFoundPage:React.FC = () => (
  <MainLayout>
    <Container>
      <img src={NotFoundBg} alt="404 bg" />
      <Body>
        <Text>
          <div>404</div>
        </Text>
      </Body>
    </Container>
  </MainLayout>
);

export default NotFoundPage;
