import React from 'react';

import {
  Page, Text, View, Document, StyleSheet, Image,
} from '@react-pdf/renderer';
import moment from 'moment';

import { Receipt } from '../../../api/models/Payment';
import Logo from '../../../assets/images/logo/dcredito-logo.png';
import { buildPaymentReceipt } from '../../../common/printingUtils';
import { formatDate, formatNumber } from '../../../common/utils';


const styles = StyleSheet.create({
  blankSpace: {
    marginBottom: 5,
    marginTop: 5,
  },
  block: {
    marginBottom: 10,
    marginTop: 10,
  },
  centerText: {
    textAlign: 'center',
  },
  detail: {
    flexDirection: 'row',
  },
  divider: {
    borderTop: 'solid',
    borderTopWidth: 0.5,
  },
  flexEnd: {
    alignItems: 'flex-end',
  },
  footer: {
    bottom: 15,
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Helvetica',
    fontSize: 12,
    justifyContent: 'space-between',
    position: 'absolute',
    right: 15,
    width: '100%',
  },
  header: {
    flexDirection: 'row',
    fontFamily: 'Helvetica',
    fontSize: 12,
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  leftText: {
    textAlign: 'left',
  },
  logo: {
    marginRight: 5,
    width: 25,
  },
  page: {
    display: 'flex',
    fontFamily: 'Courier',
    fontSize: 7.85,
    padding: 15,
  },
  rightText: {
    textAlign: 'right',
  },
});

interface Props {
  receipts: Receipt[];
}

const PaymentsReport: React.FC<Props> = ({ receipts }) => {
  const totalInPayments = () => {
    let total = 0;
    receipts.forEach(receipt => {
      total = parseFloat(total.toString()) + parseFloat((receipt.amount ?? 0).toString());
    });
    return formatNumber('money', total);
  };
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View fixed style={styles.header}>
          <View style={[styles.inline, styles.flexEnd]}>
            <Image src={Logo} style={styles.logo}/>
            <Text style={styles.leftText}>D-CREDITO - RESUMEN DE PAGOS DEL DIA</Text>
          </View>
        </View>
        <View style={styles.inline}>
          {receipts.map(receipt => (
            <View key={`receipt-${receipt.id}`} style={{  marginBottom: 15, width: '33.3%' }}>
              <Text wrap={false}>{buildPaymentReceipt(receipt, true)}</Text>
            </View>
          ))}
        </View>
        <View fixed style={[styles.footer, styles.divider]}>
          <Text style={styles.leftText}>{formatDate(moment(), 'display_date')}</Text>
          <Text style={styles.centerText}>{`Total: ${totalInPayments()}`}</Text>
          <Text style={styles.rightText} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`} />
        </View>
      </Page>
    </Document>
  );
};

export default PaymentsReport;
