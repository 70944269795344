import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import moment from 'moment/moment';
import { useSnackbar } from 'notistack';
import { useNavigate,  useSearchParams } from 'react-router-dom';

import { MonthlyClosing } from '../../../api/models/MonthlyClosing';
import { getMonthlyClosingList } from '../../../api/reports';
import {
  getCurrentPageFromParams, getPerPageFromParams, getUrlWithPagination,
} from '../../../api/routes';
import routes from '../../../common/routes';
import { formatDate } from '../../../common/utils';
import MainLayout from '../../layout/MainLayout';
import BreadCrumbs from '../../shared/BreadCrumbs';
import AlignedColumn from '../../shared/list_view_column_renderers/AlignedColumn';
import NumericColumn from '../../shared/list_view_column_renderers/NumericColumn';
import ListViewTable, { getTableRows, RenderersListType } from '../../shared/ListViewTable';
import Spinner from '../../shared/Spinner';

interface Props {
  // eslint-disable-next-line react/require-default-props
  inModal?: boolean;
  // eslint-disable-next-line no-unused-vars,react/require-default-props
  onMonthlyClosingSelect?: (user?: MonthlyClosing) => void;
}

const MonthlyClosingList: React.FC<Props> = ({ inModal, onMonthlyClosingSelect }) => {
  const [searchParams] = useSearchParams();
  const [monthlyClosings, setMonthlyClosings] = useState<MonthlyClosing[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(getCurrentPageFromParams(searchParams.get('page')));
  const [searchValue, setSearchValue] = useState('');
  const [perPage, setPerPage] = useState(getPerPageFromParams(searchParams.get('per_page')));
  const isLoading = useRef<boolean>(false);
  const isFirstLoad = useRef<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const tableHeaders = ['Fecha', <AlignedColumn align="right" value="Recuperado" />, <AlignedColumn align="right" value="Desembolsado" />, <AlignedColumn align="right" value="Generado" />, <AlignedColumn align="right" value="Gastos" />, <AlignedColumn align="right" value="Excedente" />, <AlignedColumn align="right" value="Reinvertido" />, <AlignedColumn align="right" value="Dividendos" />];

  const fetch = useCallback(async () => {
    isLoading.current = true;
    const { data, error } = await getMonthlyClosingList(currentPage, perPage, searchValue === '' ? undefined : searchValue);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (data) {
      setMonthlyClosings(data.records);
      setTotalRecords(data.pagination.total_records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
    isFirstLoad.current = false;
  }, [currentPage, perPage, enqueueSnackbar, searchValue]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetch();
      if (!inModal) {
        navigate(getUrlWithPagination(routes.reports.monthly_closings.list, currentPage, perPage), { replace: true });
      }
    }
  }, [currentPage, perPage, isFirstLoad, fetch, navigate, inModal]);

  useEffect(() => {
    if (monthlyClosings.length === 0 && !isLoading.current) {
      fetch();
    }
  }, [fetch, monthlyClosings.length, isLoading]);

  const tableRows = useMemo(() => {
    const objectFields = ['id', 'date', 'total_in_payments', 'total_disbursed', 'total_interest', 'total_expenses', 'net_profit', 're_investment_amount', 'dividends'] as Array<keyof MonthlyClosing>;
    const renderers: RenderersListType[] = [
      {
        field: 'date',
        renderer: value => <AlignedColumn align="left" value={formatDate(moment(value), 'display_date')} />,
      },
      ...['total_in_payments', 'total_disbursed', 'total_interest', 'total_expenses', 'net_profit', 're_investment_amount', 'dividends'].map(field => ({
        field,
        renderer: (value: string | number)  => <NumericColumn value={value} format="money"  />,
      })),
    ];
    return getTableRows<MonthlyClosing>(monthlyClosings, objectFields, renderers, false);
  }, [monthlyClosings]);

  const handleRowSelect = (rowId: string) => {
    if (!onMonthlyClosingSelect) {
      return;
    }

    onMonthlyClosingSelect(monthlyClosings.find(u => u?.id?.toString() === rowId.toString()));
  };

  const content = () => (
    <>
      {
        isLoading.current ? (
          <Spinner />
        ) : (
          <ListViewTable
            modelName="Cierre Mensual"
            formPageUrl={inModal ? '' : routes.reports.monthly_closings.form}
            tableHeaders={tableHeaders.map((th, idx) => ({
              id: idx.toString(),
              sortable: true,
              text: th,
            }))}
            tableRows={tableRows}
            currentPage={currentPage}
            onCurrentPageChange={newPage => setCurrentPage(newPage)}
            rowsPerPage={perPage}
            onRowsPerPageChange={newValue => setPerPage(newValue)}
            totalRecords={totalRecords}
            hasPagination
            noAddButton={inModal}
            selectable={inModal}
            onRowSelect={rowId => handleRowSelect(rowId)}
            searchPlaceholder="Fecha"
            onSearch={value => setSearchValue(value)}
          />
        )
      }
    </>
  );

  return (
    <>
      { inModal ? (
        <>{content()}</>
      ) : (
        <MainLayout>
          <BreadCrumbs currentPage="Cierres Mensuales" />
          {content()}
        </MainLayout>
      )}
    </>
  );
};

export default MonthlyClosingList;
