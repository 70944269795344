import { AxiosError } from 'axios';

import {
  api, errorResponse, successResponse,
} from './api';
import {
  CreateOrUpdateOneRequestType,
  GetListRequestType,
  GetOneRequestType,
  ResponseWithPagination,
} from './models/BaeModel';
import { MiscExpense } from './models/MiscExpense';
import { SalesmanGasExpense } from './models/SalesmanGasExpense';
import { DAILY_OPERATIONS_ROUTES, getUrlWithPagination } from './routes';

// SalesmenGasExpenses
const getSalesmanGasExpenseList: GetListRequestType<SalesmanGasExpense> = async (page, perPage, search) => {
  try {
    const response = await api.get<ResponseWithPagination<SalesmanGasExpense[]>>(getUrlWithPagination(DAILY_OPERATIONS_ROUTES.salesmen_gas_expenses.list, page, perPage, undefined, search));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const createSalesmanGasExpense: CreateOrUpdateOneRequestType<SalesmanGasExpense> = async record => {
  try {
    const response = await api.post<SalesmanGasExpense>(DAILY_OPERATIONS_ROUTES.salesmen_gas_expenses.list, record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const updateSalesmanGasExpense: CreateOrUpdateOneRequestType<SalesmanGasExpense> = async record => {
  if (!record.id) {
    return { error: 'Record ID is required' };
  }

  try {
    const response = await api.put<SalesmanGasExpense>(
      DAILY_OPERATIONS_ROUTES.salesmen_gas_expenses.single.replace(':id', record.id.toString()),
      record,
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getSalesmanGasExpense: GetOneRequestType<SalesmanGasExpense> = async id => {
  try {
    const response = await api.get<SalesmanGasExpense>(DAILY_OPERATIONS_ROUTES.salesmen_gas_expenses.single.replace(':id', id));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

// << SalesmenGasExpenses

// MiscExpenses
const getMiscExpenseList: GetListRequestType<MiscExpense> = async (page, perPage, search) => {
  try {
    const response = await api.get<ResponseWithPagination<MiscExpense[]>>(getUrlWithPagination(DAILY_OPERATIONS_ROUTES.misc_expenses.list, page, perPage, undefined, search));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const createMiscExpense: CreateOrUpdateOneRequestType<MiscExpense> = async record => {
  try {
    const response = await api.post<MiscExpense>(DAILY_OPERATIONS_ROUTES.misc_expenses.list, record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const updateMiscExpense: CreateOrUpdateOneRequestType<MiscExpense> = async record => {
  if (!record.id) {
    return { error: 'Record ID is required' };
  }

  try {
    const response = await api.put<MiscExpense>(
      DAILY_OPERATIONS_ROUTES.misc_expenses.single.replace(':id', record.id.toString()),
      record,
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getMiscExpense: GetOneRequestType<MiscExpense> = async id => {
  try {
    const response = await api.get<MiscExpense>(DAILY_OPERATIONS_ROUTES.misc_expenses.single.replace(':id', id));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

// << MiscExpenses

export {
  getSalesmanGasExpense,
  createSalesmanGasExpense,
  updateSalesmanGasExpense,
  getSalesmanGasExpenseList,
  getMiscExpense,
  createMiscExpense,
  updateMiscExpense,
  getMiscExpenseList,
};
