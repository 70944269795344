import { AxiosError } from 'axios';

import {
  api, errorResponse, successResponse,
} from './api';
import { CreateOrUpdateOneRequestType } from './models/BaeModel';
import { Payment } from './models/Payment';
import { LOAN_ROUTES } from './routes';


const createPayment: CreateOrUpdateOneRequestType<Partial<Payment>> = async record => {
  try {
    const response = await api.post<Payment>(LOAN_ROUTES.payment.list, record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

export { createPayment };
