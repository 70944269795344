import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { createPayment } from '../../../api/installments';
import { CustomerInstallmentInfo } from '../../../api/models/Customer';
import { installmentPaymentStatuses } from '../../../api/models/Installment';
import { Receipt } from '../../../api/models/Payment';
import { getCustomerInstallmentInfo } from '../../../api/sales';
import { buildPaymentReceipt, sendPrintOrder } from '../../../common/printingUtils';
import routes from '../../../common/routes';
import {
  formatAsCodeNumber,
  formatDate, formatNumber, safeNumber,
} from '../../../common/utils';
import { useAuth } from '../../../contexts/useAuth';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import MainLayout from '../../layout/MainLayout';

const Container = styled(Paper)`
  padding: 10px
`;

const CustomerNameSection = styled.div`
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
`;

const ApplyPaymentButton = styled(Button)`
  align-self: center;
  display: flex;
  margin-left: 10px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
  border-bottom: solid 0.5px #c9c9c9;
`;

const Text = styled.div`
  font-size: 1em;
  font-weight: 400;
`;

const ReceiptPreview = styled.div`
  display: flex;
  justify-content: center;
`;

const PaymentForm: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isTouchDevice = useDeviceDetect();
  const { currentUser } = useAuth();
  const customerId = params.id;
  const [installmentInfo, setInstallmentInfo] =
    useState<CustomerInstallmentInfo | null>(null);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [lastReceipt, setLastReceipt] = useState<Receipt | null>(null);
  const isLoading = useRef<boolean>(false);
  const now = moment();

  const fetch = useCallback(async (id: string) => {
    isLoading.current = true;
    const { data, error } = await getCustomerInstallmentInfo(id);
    if (error) {
      console.log('Error loading customer installment info', error);
    } else if (data) {
      setInstallmentInfo(data);
      setPaymentAmount(data.installment_amount);
    } else {
      setInstallmentInfo(null);
    }
    isLoading.current = false;
  }, []);

  useEffect(() => {
    if (customerId) {
      fetch(customerId.toString());
    }
  }, [fetch, customerId]);

  const printReceipt = (receipt: Receipt) => {
    const receiptText = buildPaymentReceipt(receipt);
    sendPrintOrder(receiptText);
  };

  const handleApplyPayment = async () => {
    if (!installmentInfo) {
      return;
    }
    isLoading.current = true;
    if (!currentUser.salesman_id) {
      enqueueSnackbar('Este usuario no puede registrar pagos. Necesita un Gestor de Cobro asignado.', { variant: 'error' });
      return;
    }
    const { data, error } = await createPayment({
      amount: paymentAmount,
      installment_id: installmentInfo.installment_id,
      salesman_id: currentUser.salesman_id ?? 0,
    });

    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (data) {
      const receipt = { ...data } as Receipt;
      setLastReceipt(receipt);
      if (isTouchDevice) {
        printReceipt(receipt);
      }
    } else {
      enqueueSnackbar('Sin respuesta del servidor', { variant: 'info' });
    }
    isLoading.current = false;
  };

  const handleNewPayment = () => {
    setLastReceipt(null);
    fetch(customerId!.toString());
  };

  const receiptPreview = () => {
    if (!lastReceipt) {
      return null;
    }
    return (
      <pre>{buildPaymentReceipt(lastReceipt)}</pre>
    );
  };

  return (
    <MainLayout>
      <Container>
        <CustomerNameSection>
          <Typography variant="subtitle1">{installmentInfo?.customer_full_name}</Typography>
        </CustomerNameSection>
        {lastReceipt ? (
          <Row>
            <Button
              variant="contained"
              color="default"
              onClick={() => printReceipt(lastReceipt)}
              fullWidth
            >
              Imprimir
            </Button>
            <ApplyPaymentButton
              variant="contained"
              color="primary"
              onClick={handleNewPayment}
              fullWidth
            >
              Nuevo Pago
            </ApplyPaymentButton>
          </Row>
        ) : (
          <Row>
            <TextField
              variant="outlined"
              label="Monto pagado"
              fullWidth
              value={paymentAmount.toString()}
              onChange={e => setPaymentAmount(safeNumber(e.target.value))}
            />
            <ApplyPaymentButton
              variant="contained"
              color="primary"
              onClick={handleApplyPayment}
              disabled={!installmentInfo || isLoading.current}
            >
              Aplicar
            </ApplyPaymentButton>
          </Row>
        )}
        {(!installmentInfo || isLoading.current) && <LinearProgress />}
        {lastReceipt ? (
          <ReceiptPreview>{receiptPreview()}</ReceiptPreview>
        ) : (
          <>
            <Row>
              <Text>No Crédito:</Text>
              <Text>{formatAsCodeNumber(installmentInfo?.loan_number ?? '')}</Text>
            </Row>
            <Row>
              <Text>Cuota No:</Text>
              <Text>{installmentInfo?.installment_number} / {installmentInfo?.total_installments}</Text>
            </Row>
            <Row>
              <Text>Fecha:</Text>
              <Text>{formatDate(now, 'display_date')}</Text>
            </Row>
            <Row>
              <Text>Hora:</Text>
              <Text>{formatDate(now, 'time')}</Text>
            </Row>
            <Row>
              <Text >Estado:</Text>
              <Text >
                {
                  installmentPaymentStatuses[
                    installmentInfo?.installment_status ?? ''
                  ]
                }
              </Text>
            </Row>
            <Row>
              <Text>Monto Cuota:</Text>
              <Text>
                {formatNumber('money', installmentInfo?.installment_amount)}
              </Text>
            </Row>
            <Row>
              <Text>Cuotas Atrasadas:</Text>
              <Text>
                {installmentInfo?.overdue_installments_count}
              </Text>
            </Row>
            <Row>
              <Text>Fecha Desembolso:</Text>
              <Text>
                {formatDate(
                  moment(installmentInfo?.loan_start_date ?? ''),
                  'display_date',
                )}
              </Text>
            </Row>
            <Row>
              <Text>Monto Desembolso:</Text>
              <Text>
                {formatNumber('money', installmentInfo?.loan_base_amount)}
              </Text>
            </Row>
            <Row>
              <Text>Fecha Vencimiento:</Text>
              <Text>
                {formatDate(
                  moment(installmentInfo?.loan_end_date ?? ''),
                  'display_date',
                )}
              </Text>
            </Row>
            <Row>
              <Text>Fecha Último Abono:</Text>
              <Text>
                {installmentInfo?.last_payment_date &&
            installmentInfo.last_payment_date !== '--'
                  ? formatDate(
                    moment(installmentInfo.last_payment_date),
                    'display_date',
                  )
                  : '--'}
              </Text>
            </Row>
            <Row>
              <Text>Monto Último Abono:</Text>
              <Text>
                {formatNumber('money', installmentInfo?.last_payment_amount)}
              </Text>
            </Row>
            <Row>
              <Text>Saldo Anterior:</Text>
              <Text>
                {formatNumber('money', installmentInfo?.previous_total_due)}
              </Text>
            </Row>
            <Row>
              <Text>Monto Pagado:</Text>
              <Text>
                {formatNumber('money', paymentAmount)}
              </Text>
            </Row>
            <Row>
              <Text>Saldo Actual:</Text>
              <Text>
                {formatNumber(
                  'money',
                  (installmentInfo?.previous_total_due ?? 0) - paymentAmount,
                )}
              </Text>
            </Row>
            <Row>
              <Text>Promotor:</Text>
              <Text>{installmentInfo?.salesman_name}</Text>
            </Row>
          </>
        )}
        <Row>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(routes.loans.dailyCustomerList)}
            fullWidth
          >
            Regresar
          </Button>
          {lastReceipt ? (
            <ApplyPaymentButton
              variant="contained"
              color="primary"
              onClick={handleNewPayment}
              fullWidth
            >
              Nuevo Pago
            </ApplyPaymentButton>
          ) : (
            <ApplyPaymentButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleApplyPayment}
              disabled={!installmentInfo || isLoading.current}
            >
              Aplicar
            </ApplyPaymentButton>
          )}
        </Row>
      </Container>
    </MainLayout>
  );
};

export default PaymentForm;
