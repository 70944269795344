import moment from 'moment/moment';

import { BaseModel } from './BaeModel';

interface Person extends BaseModel {
  first_name_1: string;
  first_name_2: string;
  lastname_1: string;
  lastname_2: string;
  id_card_type: string;
  id_card_number: string;
  date_of_birth: string | moment.Moment;
  home_address: string;
  city: string;
  municipality: string;
  phone_number: string;
  cellphone_number: string;
}

export type { Person };

const getPersonNames = <T extends Person>(person: T) => `${person?.first_name_1?.trim()} ${person?.first_name_2?.trim()}`?.trim() ?? '';

const getPersonLastNames = <T extends Person>(person: T) => `${person?.lastname_1?.trim()} ${person?.lastname_2?.trim()}`?.trim() ?? '';

const getPersonShortName = <T extends Person>(person: T) => `${person?.first_name_1?.trim()} ${person?.lastname_1?.trim()}`.trim();

const getPersonFullName = <T extends Person>(person: T) => `${getPersonNames(person)} ${getPersonLastNames(person)}`.trim();

export {
  getPersonNames, getPersonLastNames, getPersonFullName, getPersonShortName,
};
