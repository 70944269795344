import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Customer } from '../../api/models/Customer';
import CustomerList from '../sales/customer/CustomerList';

interface Props {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose: (customer: Customer | undefined) => void;
}

const CustomerSelectModal: React.FC<Props> = ({ open, onClose }) => (
  <Dialog
    open={open}
    onClose={() => onClose(undefined)}
    scroll="paper"
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description"
    maxWidth="xl"
    fullWidth
  >
    <DialogTitle id="scroll-dialog-title">Clientes</DialogTitle>
    <DialogContent dividers>
      <DialogContentText
        id="scroll-dialog-description"
        tabIndex={-1}
      >
        <CustomerList inModal onCustomerSelect={customer => onClose(customer)} />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose(undefined)} color="primary">
        Cerrar
      </Button>
    </DialogActions>
  </Dialog>
);

export default CustomerSelectModal;
