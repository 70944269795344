// MonthlyClosings
import { AxiosError } from 'axios';

import {
  api, errorResponse, successResponse,
} from './api';
import {
  CreateOrUpdateOneRequestType,
  GetListRequestType,
  GetOneRequestType,
  ResponseWithPagination,
} from './models/BaeModel';
import { MonthlyClosing } from './models/MonthlyClosing';
import { REPORTS_ROUTES, getUrlWithPagination } from './routes';

const getMonthlyClosingList: GetListRequestType<MonthlyClosing> = async (page, perPage, search) => {
  try {
    const response = await api.get<ResponseWithPagination<MonthlyClosing[]>>(getUrlWithPagination(REPORTS_ROUTES.monthly_closings.list, page, perPage, undefined, search));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const createMonthlyClosing: CreateOrUpdateOneRequestType<MonthlyClosing> = async record => {
  try {
    const response = await api.post<MonthlyClosing>(REPORTS_ROUTES.monthly_closings.list, record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const updateMonthlyClosing: CreateOrUpdateOneRequestType<MonthlyClosing> = async record => {
  if (!record.id) {
    return { error: 'Record ID is required' };
  }

  try {
    const response = await api.put<MonthlyClosing>(
      REPORTS_ROUTES.monthly_closings.single.replace(':id', record.id.toString()),
      record,
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getMonthlyClosing: GetOneRequestType<MonthlyClosing> = async id => {
  try {
    const response = await api.get<MonthlyClosing>(REPORTS_ROUTES.monthly_closings.single.replace(':id', id));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const fetchCurrentClosingData: GetOneRequestType<MonthlyClosing> = async date => {
  try {
    const response = await api.get<MonthlyClosing>(getUrlWithPagination(REPORTS_ROUTES.monthly_closings.calculate_current_closing_data, undefined, undefined, undefined, undefined, { current_closing_date: date }));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

// << MonthlyClosings

export {
  getMonthlyClosing,
  createMonthlyClosing,
  updateMonthlyClosing,
  getMonthlyClosingList,
  fetchCurrentClosingData,
};
