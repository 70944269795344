import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const ActionButton = styled(Button)`
  &.action-button {
    margin-left: 1em;
  }
`;

export default ActionButton;
