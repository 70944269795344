import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate,  useSearchParams } from 'react-router-dom';

import { getSalesmenList } from '../../../api/admin';
import { Salesman } from '../../../api/models/Salesman';
import {
  getCurrentPageFromParams, getPerPageFromParams, getUrlWithPagination,
} from '../../../api/routes';
import routes from '../../../common/routes';
import MainLayout from '../../layout/MainLayout';
import BreadCrumbs from '../../shared/BreadCrumbs';
import ListActionButton from '../../shared/list_view_column_renderers/ListActionButton';
import ListViewTable, { getTableRows, RenderersListType } from '../../shared/ListViewTable';
import Spinner from '../../shared/Spinner';

interface Props {
  // eslint-disable-next-line react/require-default-props
  inModal?: boolean;
  // eslint-disable-next-line no-unused-vars,react/require-default-props
  onSalesmanSelect?: (user?: Salesman) => void;
}

const SalesmanList: React.FC<Props> = ({ inModal, onSalesmanSelect }) => {
  const [searchParams] = useSearchParams();
  const [salesmen, setSalesmen] = useState<Salesman[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(getCurrentPageFromParams(searchParams.get('page')));
  const [searchValue, setSearchValue] = useState('');
  const [perPage, setPerPage] = useState(getPerPageFromParams(searchParams.get('per_page')));
  const isLoading = useRef<boolean>(false);
  const isFirstLoad = useRef<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const tableHeaders = ['Primer nombre', 'Segundo nombre', 'Primer apellido', 'Segundo apellido', 'Celular'];

  const fetch = useCallback(async () => {
    isLoading.current = true;
    const { data, error } = await getSalesmenList(currentPage, perPage, searchValue === '' ? undefined : searchValue);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (data) {
      setSalesmen(data.records);
      setTotalRecords(data.pagination.total_records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
    isFirstLoad.current = false;
  }, [currentPage, perPage, enqueueSnackbar, searchValue]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetch();
      if (!inModal) {
        navigate(getUrlWithPagination(routes.admin.salesmen.list, currentPage, perPage), { replace: true });
      }
    }
  }, [currentPage, perPage, isFirstLoad, fetch, navigate, inModal]);

  useEffect(() => {
    if (salesmen.length === 0 && !isLoading.current) {
      fetch();
    }
  }, [fetch, salesmen.length, isLoading]);

  const tableRows = useMemo(() => {
    const objectFields = ['id', 'first_name_1', 'first_name_2', 'lastname_1', 'lastname_2', 'cellphone_number'] as Array<keyof Salesman>;
    const renderers: RenderersListType[] = [
      {
        field: '_actions',
        renderer: record => <ListActionButton
          value={record}
          label="Conciliación"
          onClick={record => navigate(routes.admin.salesmen.conciliate.replace(':id', record.id!.toString()))}
        />,
      },
    ];
    return getTableRows<Salesman>(salesmen, objectFields, renderers, true);
  }, [salesmen]);

  const handleRowSelect = (rowId: string) => {
    if (!onSalesmanSelect) {
      return;
    }

    onSalesmanSelect(salesmen.find(u => u?.id?.toString() === rowId.toString()));
  };

  const content = () => (
    <>
      {
        isLoading.current ? (
          <Spinner />
        ) : (
          <ListViewTable
            modelName="Gestores"
            formPageUrl={inModal ? '' : routes.admin.salesmen.form}
            tableHeaders={tableHeaders.map((th, idx) => ({
              id: idx.toString(),
              sortable: true,
              text: th,
            }))}
            tableRows={tableRows}
            currentPage={currentPage}
            onCurrentPageChange={newPage => setCurrentPage(newPage)}
            rowsPerPage={perPage}
            onRowsPerPageChange={newValue => setPerPage(newValue)}
            totalRecords={totalRecords}
            hasPagination
            hasActions
            noAddButton={inModal}
            selectable={inModal}
            onRowSelect={rowId => handleRowSelect(rowId)}
            searchPlaceholder="Primer nombre"
            onSearch={value => setSearchValue(value)}
          />
        )
      }
    </>
  );

  return (
    <>
      { inModal ? (
        <>{content()}</>
      ) : (
        <MainLayout>
          <BreadCrumbs currentPage="Gestores" />
          {content()}
        </MainLayout>
      )}
    </>
  );
};

export default SalesmanList;
