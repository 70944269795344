import { AxiosError } from 'axios';

import {
  api, errorResponse, successResponse,
} from './api';
import { GetListRequestType,  ResponseWithPagination } from './models/BaeModel';
import { PaymentsSummary, Receipt } from './models/Payment';
import { getUrlWithPagination, LOAN_ROUTES } from './routes';

const getPaymentList: GetListRequestType<Receipt> = async (page, perPage, search, tenantId, extraParams) => {
  try {
    const response = await api.get<ResponseWithPagination<Receipt[]>>(
      getUrlWithPagination(
        LOAN_ROUTES.payment.list,
        page,
        perPage,
        undefined,
        search,
        { ...extraParams },
      ),
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getPaymentSummary: GetListRequestType<PaymentsSummary> = async (page, perPage, search, tenantId, extraParams) => {
  try {
    const response = await api.get<ResponseWithPagination<PaymentsSummary[]>>(
      getUrlWithPagination(
        LOAN_ROUTES.payment.payment_summary,
        page,
        perPage,
        undefined,
        search,
        { ...extraParams },
      ),
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};



export { getPaymentList, getPaymentSummary };

