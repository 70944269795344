import { Customer } from './Customer';
import { Person } from './Person';
interface Spouse extends Person {
  name?: string;
  customer?: Partial<Customer>;
}

export const defaultSpouseValues = {
  cellphone_number: '',
  first_name_1: '',
  first_name_2: '',
  id_card_number: '',
  id_card_type: '',
  lastname_1: '',
  lastname_2: '',
  phone_number: '',
};

export type { Spouse };
