import moment from 'moment/moment';

import { formatDate, formatNumber } from './utils';
import { installmentPaymentStatuses } from '../api/models/Installment';
import { Receipt } from '../api/models/Payment';

const LINE_LEN = 32;

const sendPrintOrder = (text: string) => {
  const S = '#Intent;scheme=rawbt;';
  const P =  'package=ru.a402d.rawbtprinter;end;';
  const textEncoded = encodeURI(text);
  window.location.href=`intent:${textEncoded}${S}${P}`;
};

const leftRightText = (leftText = '', rightText = '') => {
  if (leftText.length + rightText.length >= LINE_LEN) {
    return `${`${leftText}${rightText}`.substring(0, LINE_LEN-1)}\n`;
  }
  const rightLen = LINE_LEN - leftText.length;
  return `${leftText}${rightText.padStart(rightLen, ' ')}\n`;
};

const centerText = (text = '') => {
  if (text.length >= LINE_LEN) {
    return `${text.substring(0, LINE_LEN-1)}\n`;
  }
  const padLen = (LINE_LEN - text.length) / 2;
  return `${text.padStart(Math.floor(padLen + text.length), ' ')}\n`;
};

const ln = (count: number) => {
  let newLines = '';
  for (let i = 0; i < count; i++) {
    newLines += '\n';
  }
  return newLines;
};

const println = (text = '') => {
  const lines = [];
  let overflowText = '';
  if (text.length <= LINE_LEN) {
    return text + ln(1);
  }
  overflowText = text.trim();

  while (overflowText.length > LINE_LEN) {
    let lastSpaceIdx = overflowText.lastIndexOf(' ');
    if (lastSpaceIdx < 0) {
      lines.push(overflowText);
      overflowText = '';
    } else {
      while (lastSpaceIdx > LINE_LEN && lastSpaceIdx > 0) {
        lastSpaceIdx = overflowText.substring(0, lastSpaceIdx -1).lastIndexOf(' ');
      }
      if (lastSpaceIdx < 0) {
        lines.push(overflowText.substring(0, LINE_LEN -1));
        overflowText = overflowText.substring(LINE_LEN, overflowText.length).trim();
      } else {
        const line = overflowText.substring(0, lastSpaceIdx);
        lines.push(line);
        overflowText = overflowText.substring(lastSpaceIdx, overflowText.length).trim();
        if (overflowText.length <= LINE_LEN) {
          lines.push(overflowText);
          overflowText = '';
        }
      }
    }
  }
  lines.push('');
  return lines.join(ln(1));

};

const companyNameHeader = () => centerText('******* D-CRÉDITO *******');

// eslint-disable-next-line no-unused-vars
const buildPaymentReceipt: (receipt: Receipt, trim?: boolean) => string = (receipt, trim = false) => {
  let text = '';

  text += companyNameHeader();
  text += ln(1);
  text += leftRightText(
    'Fecha',
    formatDate(moment(receipt.created_at ?? ''), 'display_date'),
  );
  text += leftRightText(
    'Hora',
    formatDate(moment(receipt.created_at ?? ''), 'time'),
  );
  text += ln(1);
  text += leftRightText('No Crédito', receipt.loan_number);
  text += leftRightText('No. Recibo', receipt.receipt_number);
  text += ln(1);
  text += println(receipt.customer_full_name);
  text += ln(1);
  text += leftRightText(
    'Estado',
    installmentPaymentStatuses[
      receipt.current_installment_payment_status ?? ''
    ],
  );
  text += leftRightText(
    'Monto Cuota',
    formatNumber('money', receipt?.installment_amount) ?? '',
  );
  text += leftRightText('Moneda', 'Córdobas');
  text += leftRightText(
    'Cuotas Atrasadas',
    receipt?.overdue_payments?.toString() ?? '',
  );
  text += leftRightText(
    'Fecha Desembolso',
    formatDate(moment(receipt?.loan_start_date ?? ''), 'display_date'),
  );
  text += leftRightText(
    'Monto Desembolso',
    formatNumber('money', receipt?.loan_base_amount) ?? '',
  );
  text += leftRightText(
    'Fecha Vencimiento',
    formatDate(moment(receipt?.loan_end_date ?? ''), 'display_date'),
  );
  text += leftRightText(
    'Fecha Ultimo Abono',
    receipt?.last_payment_date && receipt.last_payment_date !== '--'
      ? formatDate(moment(receipt.last_payment_date), 'display_date')
      : '--',
  );
  text += leftRightText(
    'Monto Ultimo Abono',
    formatNumber('money', receipt?.last_payment_amount) ?? '',
  );
  text += leftRightText(
    'Saldo Anterior',
    formatNumber('money', receipt?.previous_total_due) ?? '',
  );
  text += leftRightText(
    'Monto Pagado',
    formatNumber('money', receipt?.amount) ?? '',
  );
  text += leftRightText(
    'Saldo Actual',
    formatNumber('money', receipt?.new_total_due) ?? '',
  );
  text += ln(1);
  text += leftRightText('Promotor', receipt.salesman_name);
  if (!trim) {
    text += ln(4);
  }

  return text;
};

// const buildQuoteReceipt = () => {
//   let text = '';
//   text += companyNameHeader();
//   text += ln(1);
//
//   return text;
// };

export { sendPrintOrder, buildPaymentReceipt };
