import React from 'react';

import Button from '@material-ui/core/Button';

import { BaseModel } from '../../../api/models/BaeModel';
import { Consumer } from '../../../common/types';

interface Props {
  value: string | number;
  onClick: Consumer<BaseModel>;
  // eslint-disable-next-line react/require-default-props
  // icon?: React.ReactNode;
  // eslint-disable-next-line react/require-default-props
  label?: string;
}

const ListActionButton: React.FC<Props> = ({
  value, label, onClick,
}) => {
  const record = JSON.parse(value.toString()) as BaseModel;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onClick(record);
  };

  return (
    <Button variant="outlined" onClick={handleClick}>{label}</Button>
  );
};

export default ListActionButton;
