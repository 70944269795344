import { AxiosError } from 'axios';

import {
  api, errorResponse, successResponse,
} from './api';
import {
  CreateOrUpdateOneRequestType, GetListRequestType, GetOneRequestType, ResponseWithPagination,
} from './models/BaeModel';
import { Loan } from './models/Loan';
import { getUrlWithPagination, LOAN_ROUTES } from './routes';

// >>LOAN
const getLoanList: GetListRequestType<Loan> = async (page, perPage) => {
  try {
    const response = await api.get<ResponseWithPagination<Loan[]>>(getUrlWithPagination(LOAN_ROUTES.loan.list, page, perPage));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const getLoan: GetOneRequestType<Loan> = async id => {
  try {
    const response = await api.get<Loan>(LOAN_ROUTES.loan.single.replace(':id', id));
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const createLoan: CreateOrUpdateOneRequestType<Loan> = async record => {
  try {
    const response = await api.post<Loan>(LOAN_ROUTES.loan.list, record);
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

const updateLoan: CreateOrUpdateOneRequestType<Loan> = async record => {
  if (!record.id) {
    return { error: 'Record ID is required' };
  }

  try {
    const response = await api.put<Loan>(
      LOAN_ROUTES.loan.single.replace(':id', record.id.toString()),
      record,
    );
    return successResponse(response.data);
  } catch (error) {
    return errorResponse(error as AxiosError);
  }
};

// LOAN<<

export {
  getLoanList,
  createLoan,
  getLoan,
  updateLoan,
};
