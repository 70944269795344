import {
  BILL_COUNT_HELPER_DETAILS_BILLS_OPTIONS,
  BILL_COUNT_HELPER_DETAILS_COINS_OPTIONS, BILL_COUNT_HELPER_DETAILS_USD_BILLS_OPTIONS,
  USD_EXCHANGE_RATE,
} from '../../common/constants';
import { Factory } from '../../common/types';
import { formatNumber } from '../../common/utils';

interface BillCountHelperDetail {
  bill_count_helper_id: number;
  kind: string;
  name: string;
  is_usd: boolean;
  value: number;
  count: number;
}

interface BillCountHelper {
  total: number;
  total_usd: number;
  usd_exchange_rate: number;
  bill_count_helper_details?: BillCountHelperDetail[]
}

const buildBillCountHelperDetails: Factory<BillCountHelperDetail[]> = () => {
  const details: BillCountHelperDetail[] = [];
  BILL_COUNT_HELPER_DETAILS_BILLS_OPTIONS.forEach(billOption => {
    details.push({
      count: 0,
      is_usd: false,
      kind: 'bill',
      name: `[Billetes] ${formatNumber('money', billOption)}`,
      value :billOption,
    } as BillCountHelperDetail);
  });
  BILL_COUNT_HELPER_DETAILS_COINS_OPTIONS.forEach(billOption => {
    details.push({
      count: 0,
      is_usd: false,
      kind: 'coin',
      name: `[Monedas] ${formatNumber('money', billOption)}`,
      value :billOption,
    } as BillCountHelperDetail);
  });
  BILL_COUNT_HELPER_DETAILS_USD_BILLS_OPTIONS.forEach(billOption => {
    details.push({
      count: 0,
      is_usd: true,
      kind: 'coin',
      name: `[Billetes] ${formatNumber('money', billOption, 'USD')}`,
      value :billOption,
    } as BillCountHelperDetail);
  });

  return details;
};

const buildBillCountHelper: Factory<BillCountHelper> = () => ({
  bill_count_helper_details: buildBillCountHelperDetails(),
  total: 0,
  total_usd: 0,
  usd_exchange_rate: USD_EXCHANGE_RATE,
});

export type { BillCountHelperDetail, BillCountHelper };

export { buildBillCountHelper };
