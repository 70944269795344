import React from 'react';

import AlignedColumn from './AlignedColumn';
import { formatNumber } from '../../../common/utils';

interface Props  {
  value: string | number;
  // eslint-disable-next-line react/require-default-props
  format?: 'money' | 'two-decimals',
}
const NumericColumn: React.FC<Props> = ({ value, format = 'two-decimals' }) => {
  const numericValue = parseFloat(value.toString());
  if (isNaN(numericValue)) {
    return <></>;
  }

  return (
    <AlignedColumn align="right" value={formatNumber(format, numericValue)} />
  );
};

export default NumericColumn;
