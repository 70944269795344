import React from 'react';

import { FormControl, FormHelperText } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
  useFormContext, RegisterOptions, Controller, FieldError,
} from 'react-hook-form';
import styled from 'styled-components';

const InputWrapper = styled.div`
  padding: 0;
  margin-top: 11px;

  .MuiFormGroup-row {
    justify-content: space-between;
  }
  
  label {
    margin: 0 10px 0 0
  }
`;

interface FieldOptions {
  label: string;
  value: string;
}

interface Props {
  name: string;
  label: string;
  // eslint-disable-next-line react/require-default-props
  placeholder?: string;
  // eslint-disable-next-line react/require-default-props
  validations?: RegisterOptions;
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
  options: FieldOptions[];
}

const FormRadioGroupField: React.FC<Props> = ({
  name,
  label,
  validations,
  required = false,
  options,
}) => {
  const { control } = useFormContext();

  const fieldValidations = validations ?? {};
  if (required) {
    fieldValidations.required = required;
  }

  const getErrorMessage = (error: FieldError) => {
    let message = error?.message;
    if (message === '' && required) {
      message = 'Este campo es requerido';
    }
    return message;
  };

  return (
    <>
      <Controller
        render={({ field, formState: { errors } }) => (
          <InputWrapper>
            <FormControl error={Boolean(errors[name])} fullWidth>
              {/*<Select { ...field }>*/}
              {/*  {options.map(option => (*/}
              {/*    <MenuItem value={option.value} key={`${name}-option-${option.value}`}>{option.label}</MenuItem>*/}
              {/*  ))}*/}
              {/*</Select>*/}
              <RadioGroup row aria-label={name} defaultValue="top" {...field}>
                <FormControlLabel
                  control={<FormLabel
                    component="label"
                  >
                    {label}
                  </FormLabel>}
                  label=""
                  labelPlacement="start"
                />
                {options.map(option => (
                  <FormControlLabel
                    value={option.value}
                    key={`${name}-option-${option.value}`}
                    control={<Radio color="primary" />}
                    label={option.label}
                    labelPlacement="start"
                  />
                ))}
              </RadioGroup>
              <FormHelperText>{getErrorMessage(errors[name] as FieldError)}</FormHelperText>
            </FormControl>
          </InputWrapper>
        )}
        name={name}
        control={control}
        rules={fieldValidations}
      />
    </>
  );
};

export default FormRadioGroupField;
