import React from 'react';

import moment from 'moment';

import AlignedColumn from './AlignedColumn';
import { DateFormat, TextAlignType } from '../../../common/types';
import { formatDate } from '../../../common/utils';

interface Props  {
  value: string | number;
  // eslint-disable-next-line react/require-default-props
  valueFormat?: DateFormat,
  // eslint-disable-next-line react/require-default-props
  displayFormat?: DateFormat,
  // eslint-disable-next-line react/require-default-props
  align?: TextAlignType,
}
const DateColumn: React.FC<Props> = ({
  value, valueFormat, displayFormat = 'display_date', align = 'left',
}) => {
  const dateValue = moment(value, valueFormat);
  if (!dateValue.isValid()) {
    return <></>;
  }

  return (
    <AlignedColumn align={align} value={formatDate(dateValue, displayFormat)} />
  );
};

export default DateColumn;
